.registercontainer{
    padding-left: 25%;
    padding-right: 25%;
    padding-top: 10%;
    padding-bottom: 5%;
}

.inputregister{
    display: block;
    margin-bottom: 5%;

    height: 40px;
    width: 100%;
    padding: 5%;
    font-size: 15px;

    border-radius: 5px;
    outline: none;
    border: 1px solid black;
}

.inputregister:hover{
    background-color: aliceblue;
}

.inputregister:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.register-info{
    margin-bottom: 10px;    
}

.register-note{
    font-size: small;
    text-align: center;

    margin-top: 5%;
    width: 100%;
}

.register-button{
    margin-left: 30%;
}

@media only screen and (max-width: 500px) {
    .registercontainer{
        padding-top: 30%;
    }

    .registercontainer{
        padding-left: 15%;
        padding-right: 15%;
    }

    .register-button{
        margin-left: 0%;
    }

    .register-note{
        margin-top: 10%;
    }
}