.contractorprofile-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.contractorprofile-editmodal-description-container{
    margin-top: 2%;
}

.contractorprofile-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contractorprofile-title{
    font-weight: bold;
    font-size: 36px;
}

.contractorprofile-save-container{
    display: flex;
    justify-content: right; 
    margin-top: 2%;
}

.contractorprofile-section-title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.contractorprofile-property-details-desc{
    width: 100%;
    padding: 10px;
    
    border: none;
    border-left: 2px solid whitesmoke;

    background-color: #F6F6FA;
    box-shadow: inset 0 0 3px gray;
}

.contractorprofile-property-details-desc:hover{
    background-color: #EEF4FF;
}

.contractorprofile-property-details-desc:focus{
    background-color: #EEF4FF;
    border: none;
    outline: none;
    border-left: 2px solid #006AFF;
}

.contractorprofile-section-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 3%;
}

.contractorprofile-licensedetails-container{
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.carousel {
    margin: auto;
    position: relative;
}

.slide{
    display: none;
}

.slide > img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    padding: 5rem;
}

.button-prev, .button-next {
    padding: 16px;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    top: 50%;
    border: none;
    background: none;
    transition: 0.6s ease;
    border-radius: 3px;
    user-select: none;
}

.button-prev{
    left: 1rem;
}
.button-next{
    right: 1rem;
}

.button-prev:hover, .button-next:hover {
    background-color: rgba(0,0,0,0.5);
  }
