.contractorproject-changelog-log{
    font-size: small;
}

.contractorproject-changelog-title{
    font-size: 20px;
    font-weight: bold;
    color: #006AFF;
}

.contractorproject-changelog-date:hover{
    cursor: pointer;
    color: #006AFF;
}