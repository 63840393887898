.contact-container {
    position: relative;
    font-family: 'Lexend Deca', sans-serif !important;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    background-color: #FCFCFF;
}

.contact-background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.contact-background-image {
    width: 115vw;
    object-fit: cover;
}

.contact-content {
    z-index: 1;
    padding: 3% 10%;
}

.contact-body {
    padding-top: 80px;
}

.contact-body-desc {
    margin-top: 3%;
    margin-bottom: 3%;

    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.contact-body-title {
    font-size: 50px;
    font-weight: 700;
    line-height: 64px;
}

.connect-message-input-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-self: left;

    width: 60%;

    gap: 25px;
    height: 50px;
}

.connect-message-input {
    width: 100%;
    height: 50px;
    padding: 10px;
    padding-left: 20px;
    border: none;
    border-radius: 5px;

    background: rgba(0, 106, 255, 0.25);
}

.connect-message-input::placeholder {
    color: rgba(0, 106, 255, 0.65);
}

.connect-message-message {
    margin-top: 2%;

    width: 100%;
    height: 200px;
    padding: 10px;
    padding-left: 20px;
    border: none;
    border-radius: 5px;

    background: rgba(0, 106, 255, 0.25);
}

.connect-message-message::placeholder {
    color: rgba(0, 106, 255, 0.65);
}

@media (max-width: 1000px) {
    .contact-body-title{
        margin-top: 10%;

        font-size: 40px;
        line-height: 50px;
    }

    .contact-body-desc{
        width: 60vw;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .connect-message-input-container{
        margin-top: 5vh;
        width: 80vw;
    }
}

@media (max-width: 600px) {
    .contact-body-title{
        margin-top: 10%;

        font-size: 30px;
        line-height: 40px;
    }

    .contact-body-desc{
        width: 80vw;

        display: flex;
        flex-direction: column;
        gap: 20px;

        font-size: 16px;
        line-height: 24px;
    }
    
    .connect-message-input-container{
        margin-top: 5vh;
        width: 80vw;
    }
}