.permitmap-projectlist-container{
    padding: 5%;
    max-height: 94vh;
    overflow-y: auto;
}

.permitmap-projectlist-title{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
}

.permitmap-projectlist-houses{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.permitmap-projectlist-house{
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    transition-duration: 0.3s;
    background-color: white;
}

.permitmap-projectlist-house:hover{
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
    cursor: pointer;
}

.permitmap-housedetail-container{
    padding: 5%;
    max-height: 94vh;
    overflow-y: auto;
}

.permitmap-housedetail-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10%;
}

.permitmap-housedetail-title{
    font-weight: bold;
    font-size: 20px;
}

.permitmap-housedetail-project-container{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.permitmap-housedetail-project{
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    transition-duration: 0.3s;
    background-color: white;
}

.permitmap-housedetail-project:hover{
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
    cursor: pointer;
}

.permitmap-projectdetail-conatiner{
    padding: 5%;
}

.permitmap-projectdetail-header{
    display: flex;
    justify-content: space-between;
    gap: 10%;
    align-items: center;
    margin-bottom: 10px;
}

.permitmap-projectdetail-title{
    font-weight: bold;
    font-size: 20px;
}

.permitmap-projectlist-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.permitmap-projectlist-bottomoptions{
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.permitmap-projectlist-options{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.permitmap-projectlist-options-page{
    text-align: center;
}

.permitmap-projectlist-loading-spinner{
    height: 70px;
    width: 70px;
    border-width: 10px;
}

.permitmap-projectlist-loading-container{
    padding-top: 30%;
    display: flex;
    justify-content: center;
}

.permitmap-projectdetail-container{
    padding: 5%;
    max-height: 94vh;
    overflow-y: auto;
}

.permitmap-projectdetail-table{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 5%;
}

.permitmap-projectdetail-row{
    display: grid;
    grid-template-columns: 1fr 2fr;
}