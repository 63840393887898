.navbar-container {
    height: 6%;
    min-height: 40px;
    
    width: 100%;
    position: fixed;
    z-index: 999;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    padding: 0px;
    padding-left: 10%;
    padding-right: 10%;

    border-bottom: 1px solid lightgray;
    background-color: white;
}

.navbar-container a {
    color: black;
    text-decoration: none;
    height: 6vh;
}

.navbar-container a:hover {
    color: #006AFF;
}

.navbar-logout{
    float: right;
    margin-left: auto;
}

.navbar-login{
    float: right;
    margin-left: auto;
}

.navbar-contractor-logout{
    float: right;
}

.navbar-profile{
    float: right;
    margin-left: auto;
}

.navbar-logo{
    width: 50px;
    max-width: 6vh;

    min-height: 35px;
}

.navbar-item{
    height: fill-available;

    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-container-phone{
    display: none;
}

.navbar-phone-dropdown{
    position: fixed;
    z-index: 1;

    top: 5%;
    right: 0px;
    
    width: 150px;
    height: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.navbar-phone-dropdown a {
    color: black;
    text-decoration: none;
}

.navbar-phone-dropdown a:hover {
    color: #006AFF;
}

@media only screen and (max-width: 500px) {
    .navbar-item{
        height: 24px;
    }

    .navbar-container {
        padding-left: 5%;
        padding-right: 5%;
        min-height: 6%;

        gap: 10px;
    }

    .navbar-container{
        display: none;
    }

    .navbar-container-phone{
        display: block;

        height: 5%;
        min-height: 40px;
        
        width: 100%;
        position: fixed;
        z-index: 1;
    
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
    
        padding: 0px;
        padding-left: 10%;
        padding-right: 10%;
    
        border-bottom: 1px solid lightgray;
        background-color: white;
    }

    .navbar-logo{
        width: 40px;
    }

    .navbar-title-name{
        display: none;
    }
}