.uploadfiles{
    overflow-y: hidden;
}

.contractorproject-owner-uploadfiles-container{
    padding-top: 6vh;
    display: grid;
    grid-template-columns: 3fr 7fr;
    height: 100vh;
    overflow-y: hidden;
}

.uploadfiles-container{
    padding-left: 17%;

    padding-top: 6vh;
    display: grid;
    grid-template-columns: 3fr 7fr;
    height: 100vh;
    overflow-y: hidden;
}

.contractorproject-uploadfiles-container{
    padding-left: 17%;

    padding-top: 6vh;
    display: grid;
    grid-template-columns: 3fr 7fr;
    height: 100vh;
    overflow-y: hidden;
}

.uploadfiles-right{
    padding: 5%;

    overflow-y: scroll;
}

.uploadfiles-list-title{
    font-weight: bold;
    font-size: 20px;
}

.uploadfiles-upload-title{
    font-weight: bold;
    font-size: 20px;
}

.uploadfiles-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;

    gap: 2%;

    height: 60%;    
}

.uploadfiles-file-item:hover{
    cursor: pointer;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
}

.uploadfiles-left{
    position: relative;
    height: 94vh;
    background-color: whitesmoke;

    z-index: 800;
}

.uploadfiles-shown-container{
    background-color: whitesmoke;
    height: stretch;
    display: flex;
    justify-content: center;

    z-index: 800;
}

.uploadfiles-file-shown{
    height: 94vh;
    width: stretch;

    z-index: 800;
}

.uploadfiles-left-placeholder{
    display: flex;
    justify-content: center;
    align-items: center;
    height: stretch;
}

.uploadfiles-header{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.uploadfiles-details-container{
    border-radius: 5px;
    padding: 2%;
    background-color: white;
    margin-top: 2%;

    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.uploadfiles-file-item{
    border-radius: 5px;
    background-color: white;
    border: 1px solid white;

    padding: 2%;

    width: 100%;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.uploadfiles-file-item-date{
    font-size: small;
    text-align: right;
}

.uploadfiles-file-item-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.uploadfiles-file-item-active{
    border: 1px solid #0069d9;
}

.uploadfiles-ownerlink-container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 10px;

    border-radius: 5px;
    padding: 2%;
    background-color: white;
    margin-top: 2%;

    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.uploadfiles-file-item-name-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.uploadfiles-file-item-options-icon:hover{
    color: #0069d9;
}

.uploadfiles-upload-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractortakeoff-groupdetails-modal-input{
    border-radius: 5px;
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;

    outline: none;
    border: 1px solid lightgray;

    width: 100%;

    background-color: #F6F6FA;
}

.contractortakeoff-groupdetails-modal-input:hover{
    background-color: #EEF4FF;
}

.contractortakeoff-groupdetails-modal-input:focus{
    background-color: #EEF4FF;
    border: 1px solid #006AFF;
}