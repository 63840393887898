.about-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;

    overflow: hidden;
}

.contractorsabout-container{
    position: relative;
}

.contractorsabout-background{
    z-index: 1;
}

.contractorsabout-background-img{
    position: absolute;

    top: -0%;
    width: 300%;
    margin-left: -50%;

    /*flip across x and yaxis*/
    transform: scaleX(-1);

    z-index: 1;
}

.contractorsabout-body{
    position: relative;
}

.about-title{
    font-size: 64px;
    font-weight: 800;
    line-height: 64px;
    color: white;

    width: 70%;
}

.contractorabout-desc-try{
    margin-top: 2%;
    margin-bottom: 2%;

    font-size: 18px;
    color: white;
}

.contractorsabout-register-container{
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;

    z-index: 3;
}

.about-background{
    background-image: url("https://bobyard-public-images.s3.us-west-2.amazonaws.com/about-background4.png");
    background-size: cover;
}

.about-desc-container{
    font-size: 18px;

    color: white;

    margin-top: 5%;
    margin-bottom: 5%;
}

.about-header{
    height: 70vh;
    padding-top: 20%;
}

.contractorsabout-header{
    position: relative;

    overflow: hidden;

    min-height: 85vh;
    padding-top: 0%;

    background-color: #043873;

    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 10%;
}

.about-team-title{
    font-size: 64px;
    font-weight: 800;
    line-height: 64px;

    font-family: 'Inter', sans-serif !important;

    padding-top: 10%;
    margin-bottom: 5%;
}

.about-desc-title{
    font-size: 36px;
    font-weight: 800;
    line-height: 70px;

    text-align: left;
}

.about-team-body{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;

    overflow: hidden;

    position: relative; 
}

.about-team-container{
    padding-bottom: 5%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4%;
}

.about-desc-image{
    width: 50vw;
}

.about-desc-image-container{

}

.about-desc-bottom-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid black;
    width: 100vw;
}

.about-team-person{
    background-color: white;
    border-radius: 5px;
    padding: 2%;
    box-shadow: 5px 5px 10px whitesmoke;
}

.about-team-person-header{
    padding-top: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-team-image{
    width: 20vw;
    height: 20vw;
    object-fit: cover;
    border-radius: 50%;
}

.about-team-person-name{
    font-weight: bold;
    font-size: 20px;
    margin-top: 2%;
}

.about-team-person-body{
    padding: 4%;
}

.about-team-person-links{
    margin-top: 2%;

    font-size: 30px;
}

.about-header-waitlist{
    text-align: center;
    margin-top: 5%;
}

.about-team-body-container{
    position: relative;
    z-index: 2;

    overflow: hidden;
}

.contractorsabout-team-background1-img{
    position: absolute;   

    z-index: 1;

    top: 2%;
    margin-left: 50%;
    width: 70%;
}

.contractorsabout-team-background2-img{
    position: absolute;

    z-index: 1;

    top: 30%;
    margin-left: -70%;
    width: 125%;
}

@media only screen and (max-width: 500px) {
    .about-container{
        padding-left: 10%;
        padding-right: 10%;
    }

    .about-title{
        width: 100%;
        font-size: 40px;
        line-height: 40px;
    }

    .about-background{
        overflow: hidden;
    }

    .homepage-final-title{
        font-size: 36px;
        line-height: 40px;
    }

    .about-desc-container{
        font-size: 15px;
    }

    .about-team-person-body{
        font-size: 15px;
    }

    .about-team-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .contractorsabout-background-img{
        width: 500%;
        margin-left: -70%;

        /*opacity*/
        filter: opacity(1);
    }

    .about-team-title{
        font-size: 40px;
        line-height: 40px;
        margin-top: 20%;
    }

    .contractorsabout-team-background1-img{
        width: 200%;
        margin-left: -10%;
    }

    .contractorsabout-team-background2-img{
        margin-top: 50%;
        width: 300%;
        margin-left: -140%;
    }
}