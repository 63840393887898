.contractormap-containers{
    display: flex;
    flex-direction: row;
    height: 94vh;
}

.contractormap-map{
    width: 60%;
    height: 100%;
    margin-top: 6vh;
}

.contractormap-details{
    width: 40%;
    overflow-y: scroll;
    height: 100vh;
}

.contractormap-selectedproject-container{
    padding: 5%;
    margin-top: 6vh;
}

.contractormap-projectlist{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.contractormap-projectlist-project{
    background-color: white;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    border-radius: 5px;
    padding: 10px;

    overflow: hidden;
}

.contractormap-projectlist-project:hover{
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
    cursor: pointer;
}

.contractormap-selectedproject-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.contractormap-selectedproject-title{
    font-weight: bold;
    font-size: 20px;
}

.contractormap-projectlist-container{
    padding: 5%;
    margin-top: 50px;
    overflow-y: auto;
}

.contractormap-projectlist-title{
    font-size: 20px;
    font-weight: bold;
}

.contractormap-projectlist-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5%;
}

.contractormap-projectlist-project-desc{
    margin: 10px;
    padding-left: 5px;
    border-left: 2px solid #006AFF;
    padding-top: 5px;
    padding-bottom: 5px;
}

.contractormap-projectlist-project-buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.projectmap-projectdetail-sow-title{
    font-size: 20px;
    font-weight: bold;
}

.contractormap-projectlist-legendtable{
    table-layout: fixed;
    margin-bottom: 3%;
}

.projectmap-addedprojects-title{
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.pdm-sow-section{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
    background-color: white;
    margin-bottom: 2%;
}

.pdm-sow-section-header{
    color: #006AFF;
    padding: 2%;    
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.pdm-sow-section-header:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}