.question-bid-item-left{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
}

.question-detail-item{
    align-items: center;
    margin-bottom: 1%;
}

.question-file{
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 2%;
    border-radius: 5px;
    border: 1px solid lightgray;

    background-color: white;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.question-file-delete{
    border: 1px solid red;
}

.questiondetail-publish-container{
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 10%;
    align-items: center;

    margin-top: 5%;
}