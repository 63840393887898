.contractorsow-item-bid{
    width: 100%;
    height: 24px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-right: 1px;
    font-size: small;
}

.contractorsow-subsection-items-container{
    display: grid;
    grid-template-columns: 10fr 10fr;
    align-items: center;
    gap: 10px;
    padding: 2%;
}

.contractorsow-item-detail-container{
    display: grid;
    grid-template-columns: 10fr 1fr 10fr;
    gap: 4px;
}

.contractorsow-subsection-quantity{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
    font-size: small;
    width: 100%;
}

.contractorsow-subsection-extra-desc{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: small;
    width: 100%;

    max-height: 100px;
}

.contractorsow-subsections-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;

    padding-right: 5%;
}

.contractorsow-subsection-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 2%;
    padding-right: 1%;
}

.contractorsow-section-total{
    font-weight: bold;
    color: #006AFF;
}

.contractorsow-subsection-buttons-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.contractorsow-section-header{
    position: relative;

    padding: 2%;
    border-radius: 5px;

    font-size: 18px;

    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.contractorsow-section-header:hover{
    cursor: pointer;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
}

.contractorsow-subsection-header{
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 1%;
    padding-left: 2%;
    padding-right: 3%;

    background-color: #EEF4FF;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.contractorsow-subsection-header:hover{
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.contractorsow-subsection-header-left{
    display: flex;
    align-items: center;
    gap: 10px;

    width: fill-available;
}

.contractorsow-updown-container{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding-left: 1px;
    padding-right: 1px;
    
    height: 36px;

    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
}

.contractorsow-subsection-updown-container{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding-left: 1px;
    padding-right: 1px;
    
    height: 32px;

    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);

    background-color: white;
}

.contractorsow-addsection{
    margin-top: 2%;
}

.contractorbid-extra-section-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractorbid-options-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 2%;
    margin-bottom: 2%;
}

.contractorsow-subsection-materials-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.contractorsow-item-bids{
    padding-left: 10%;
}

.contractorsow-subsection-desc{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: small;
    width: 100%;

    max-height: 100px;
}

.contractorsow-options-header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
}

.contractorsow-options{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    align-items: center;

    margin-bottom: 2%;
    height: 70px;
}

.contractorsow-subsection-body-container{
    padding: 2%;
}

.contractorsow-subsections{
    padding-left: 5%;
    padding-right: 5%;
}

.contractorbid-section-option-modal{
    top: 20%;
}

.contractorprojectsow-section-detail-button{
    margin-right: 0px;
    margin-left: auto;

    background-color: white;
    border: none;
}

.contractorprojectsow-section-detail-button:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractortakeoff-groupdetails-modal-section-template-name{
    padding-left: 5px;

    border-radius: 5px;
    outline: none;
    border: 1px solid lightgray;
    background-color: whitesmoke;

    margin-bottom: 1%;
}

.contractortakeoff-groupdetails-modal-section-template-name:hover{
    background-color: aliceblue;
}

.contractortakeoff-groupdetails-modal-section-template-name:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.contractorsow-section-comment-container{
    position: absolute;
    left: -28%;
    top: 0%;
}

.contractorsow-subsection-comment-container{
    position: absolute;
    left: 110%;
    top: 0%;

    width: 30%;

    background-color: white;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    padding: 5px;

    padding-bottom: 0px;

    z-index: 1;
}

.contractorprojectbid-subsection-comment-icon{
    position: absolute;
    left: 105%;
    top: 30%;
    
    white-space: nowrap;

    font-size: small;
}

.contractorprojectbid-subsection-comment-icon:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractorsow-section-comment-icon{
    position: absolute;
    left: 103%;
    top: 30%;

    white-space: nowrap;
}

.contractorsow-section-comment-icon:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractorsow-section-document-icon{
    position: absolute;
    left: 90%;
    top: 30%;
}

.contractorsow-section-document-icon:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractorsow-section-redirect-container{
    position: absolute;
    color: #212529;
    left: -12%;
    top: 30%;
}

.contractorsow-section-redirect-icon{
    rotate: 270deg;
}

.contractorsow-section-redirect-icon:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractorsow-subsection-comment-icon{
    position: absolute;
    white-space: nowrap;
    left: 110%;
    top: 30%;

    font-size: small;
}

.contractorsow-subsection-comment-icon:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractorsow-section-comment-header{
    padding: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.contractorprojectsow-section-header{
    position: relative;
}


.contractorsow-subsection-move-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    align-items: center;
}

.contractorownersow-subsection-expand{
    display: grid;
    grid-template-columns: 1fr 30fr;
    width: fill-available;
}

.contractorsow-options-templates-infohover{
    position: absolute;
    top: 0px;
    right: 5px;
}

.contractorsow-options-templates{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: white;
    border-radius: 5px;
    padding: 2%;
    padding-left: 5%;
    padding-right: 10%;
    gap: 10px;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;

    height: inherit;

    position: relative;
}

.contractorsow-subsection-delete{
    height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 15px;

    background-color: inherit;
}

.contractorsow-section-redirect-container{
    position: absolute;
    color: #212529;
    left: -12%;
    top: 30%;
}

.contractorsow-section-redirect-icon{
    rotate: 270deg;
}

.contractorsow-section-redirect-icon:hover{
    cursor: pointer;
    color: #006AFF;
}

.quill-container .ql-editor{
    min-height: 400px;
}

.quill-save-hint {
    color: #c7c7c7
}

[commentid] {
    /*change color to blue*/
    background-color: rgba(0, 106, 255, 0.5);
}

[commentid]:hover {
    cursor: pointer;
    background-color: #006AFF;
}

.contractordocument-comment-list{
    position: absolute;
    width: 28%;
}
.contractordocument-section-document-comment-container{
    /* position: absolute; */
    /* left: -30%;
    top: 0%; */
    /* width: 28%; */

    background-color: white;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    padding: 5px;

    padding-bottom: 0px;

    z-index: 1;
}

.contractordocument-insert-comment-button {
    all: unset;
    position: absolute;
    left: 15%;
}

.contractordocument-insert-comment-button:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractordocument-toggle-comment-button {
    position: absolute;
    left: 15.8%;
    margin-top: 2%;
}

.contractordocument-toggle-comment-button:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractordocument-comment-list {
    position: absolute;
    width: 17%;
    right: 2%;
    z-index: 1;
}

.contractordocument-comment {
    margin-bottom: 3%;
}

.ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

.ql-snow.ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }