.drawsowpdf-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 5%;

    background-color: white;
    border-radius: 5px;
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
    gap: 10px;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;

    height: inherit;
}

.drawfinalpdf-spinner{
    padding: 0px;
    margin: 0px;
}

.drawfinalpdf-spinner-container{
    padding: 0px;
    margin: 0px;
}

.drawfinalpdf-container{
    margin-bottom: 0px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
}

.renderpdf-spinner-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}