.contractortakeoff-floorplan-container{
    overflow: hidden;
    padding-top: 6vh;
    height: 100vh;
    width: 100vw;
}

.floorplan-toolbar{
    background-color: white;
    border-bottom: 1px solid lightgray;
    box-shadow: 0px 0px 5px 0px lightgray;

    display: grid;
    grid-template-columns: 1fr 1.5fr 5fr 5fr 2.5fr 2fr 1fr;
    align-items: center;

    padding: 2px;

    padding-left: 10px;
    padding-right: 20px;
}

.floorplan-details-container{
    display: grid;
    grid-template-columns: 8fr 2fr;
}

.floorplan-area-container{
    width: 100%;
    height: 95vh;
    z-index: 2;
    background-color: white;
    border: 1px solid lightgray;
    border-top: none;

    padding-right: 5px;

    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 10vh;
    padding-right: 10px;
}

.floorplan-floorplan-container{
    position: relative;
    overflow: scroll;
}

.contractortakeoff-shapearea-modal{
    position: absolute;
    top: 20px;

    width: 95%;

    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px 0px;

    z-index: 1;
}

.contractortakeoff-groupdetails-modal{
    width: 20%;
    left: 60%;
    top: 10%;
}

.contractortakeoff-shapearea-container{
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 5px;
    padding-right: 5px;

    border-radius: 5px;
}

.contractortakeoff-shapearea-container:hover{
    background-color: aliceblue;
    cursor: pointer;
}

.contractortakeoff-shapearea-container-selected{
    background-color: aliceblue;
    color: #006efa;
}

.contractortakeoff-shapearea-modal-container-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-left: 5px;
    padding-right: 5px;
}

.contractortakeoff-groupdetails-container{
    width: 100%;

    margin: 2%;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 1px 0px lightgray;
    border-radius: 5px;
}

.contractortakeoff-groupdetails-name{
    font-weight: bold;
    border-radius: 5px;
    outline: none;
    border: 1px solid lightgray;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px;

    width: 100%;

    background-color: whitesmoke;
}

.contractortakeoff-groupdetails-name:hover{
    background-color: aliceblue;
}

.contractortakeoff-groupdetails-name:focus{
    background-color: aliceblue;
    border: 1px solid #006efa;
}

.contractortakeoff-groupdetails-name-other{
    font-weight: bold;
    border-radius: 5px;
    outline: none;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px;

    width: 100%;
}

.floorplan-area-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 10px;
    padding-bottom: 5px;
    width: 100%;
    padding-right: 0px;
}

.floorplan-area-header{
    font-weight: bold;
    font-size: 20px;
}

.floorplan-pages-container{
    display: flex;
    flex-direction: column;

    width: 100%;
}

.contractortakeoff-page-container{
    width: 100%;

    padding: 2%;
    margin: 2%;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 2px 0px lightgray;
    border-radius: 5px;
}

.contractortakeoff-page-container:hover{
    box-shadow: 0px 0px 3px 0px gray;
    cursor: pointer;
}

.contractortakeoff-page-container-active{
    font-weight: bold;
    background-color: aliceblue;
}

.contractortakeoff-page-name-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractortakeoff-page-count{
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: #006efa;
    background-color: rgb(187, 226, 250);
}

.contractortakeoff-page-icon:hover{
    cursor: pointer;
    color: #006efa;
}

.contractortakeoff-shapearea-options-modal{
    top: 10%;
    left: 60%;
    width: 20%;

    overflow: visible;
}

.modal-backdrop{
    opacity: 0.3 !important;
}

.contractortakeoff-shapearea-modal-container-options-option-group{
    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-top: 2%;
    border-radius: 5px;

    background-color: whitesmoke;
}

.contractortakeoff-shapearea-modal-container-options-option-group:hover{
    background-color: aliceblue;
    cursor: pointer;
}

.contractortakeoff-groupdetails-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 10px;

    padding: 2%;

    background-color: whitesmoke;
    border-radius: 5px;
}

.contractortakeoff-groupdetails-header:hover{
    cursor: pointer;
}

.contractortakeoff-groupdetails-header:hover .contractortakeoff-groupdetails-container{
    box-shadow: 0px 0px 3px 0px gray;
}

.contractortakeoff-groupdetails-count{
    border-radius: 5px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;

    float: right;

    white-space: nowrap;
}

.contractortakeoff-groupdetails-color{
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    border-radius: 50%;
}

.contractortakeoff-groupdetails-color:hover{
    cursor: pointer;
}

.contractortakeoff-groupdetails-options-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.contractortakeoff-addpage-page-container{
    border-radius: 5px;
    padding: 2%;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 5px 0px lightgray;

    margin-bottom: 3%;
}

.contractortakeoff-addpage-page-container-uploadfile{
    border-radius: 5px;
    padding: 2%;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 5px 0px lightgray;

    margin-bottom: 3%;

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contractortakeoff-addpage-page-name{
    font-size: 20px;
    font-weight: bold;
}

.contractortakeoff-addpage-input-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    margin-top: 1%;
    margin-bottom: 2%;
}

.contractortakeoff-addpage-input{
    width: 100px;

    border-radius: 5px;
    outline: none;

    border: 1px solid lightgray;
    padding-left: 4px;

    background-color: whitesmoke;
}

.contractortakeoff-addpage-input:hover{
    background-color: aliceblue;
}

.contractortakeoff-addpage-input:focus{
    border: 1px solid #006efa;
    background-color: aliceblue;
}

.contractortakeoff-page-name{
    width: 80%;
    white-space: wrap;
}

.contractortakeoff-no-pages{
    padding: 2%;
}

.floorplan-toolbar-pages{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contractortakeoff-groupdetails-modal-section-header{
    font-weight: bold;
}

.contractortakeoff-groupdetails-modal-section{
    margin-bottom: 2%;
}

.contractortakeoff-groupdetails-modal-section-delete-warning{
    font-style: italic;
    font-size: 14px;
    line-height: 16px;

    margin-bottom: 2%;
}

.contractortakeoff-groupdetails-modal-section-delete{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.contractortakeoff-addpage-page-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractortakeoff-addpage-image-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contractortakeoff-shapearea-container-info{
    display: grid;
    grid-template-columns: 10fr 6fr;
    gap: 5px;

    width: 90%;

    white-space: nowrap;
}

.contractortakeoff-scale-input-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    margin-top: 1%;
    margin-bottom: 2%;
}

.contractortakeoff-scale-input{
    border-radius: 5px;
    outline: none;

    border: 1px solid lightgray;
    padding-left: 4px;

    background-color: whitesmoke;

    width: 100px;
}

.contractortakeoff-scale-input:hover{
    background-color: aliceblue;
}

.contractortakeoff-scale-input:focus{
    border: 1px solid #006efa;
    background-color: aliceblue;
}

.floorplan-toolbar-scale{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.floorplan-no-files{
    padding: 10%;
}

.contractortakeoff-deletepage-container{
    padding: 2%;
    margin: 2%;
}

.contractortakeoff-groupdetails-modal-section-select{
    width: 100%;

    border-radius: 5px;
    outline: none;

    border: 1px solid lightgray;
    padding-left: 4px;

    background-color: whitesmoke;
}

.contractortakeoff-groupdetails-modal-section-select:hover{
    background-color: aliceblue;
}

.contractortakeoff-groupdetails-modal-section-select:focus{
    border: 1px solid #006efa;
    background-color: aliceblue;
}

.contractortakeoff-groupdetails-modal-section-input{
    width: 80%;

    border-radius: 5px;
    outline: none;
    border: 1px solid lightgray;
    padding-left: 5px;

    background-color: #f5f5f5;
}

.contractortakeoff-groupdetails-modal-section-input:hover{
    background-color: aliceblue;
}

.contractortakeoff-groupdetails-modal-section-input:focus{
    background-color: aliceblue;
    border: 1px solid #006efa;
}

.contractortakeoff-shapearea-link-icon{
    font-size: 10px;
    vertical-align: middle;
}

.contractortakeoff-groupdetails-modal-section-header-info{
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    padding: 0px;
    margin-top: -5px;
}

.contractortakeoff-subsectiondetails-container{
    border: 1px solid lightgray;
    border-radius: 5px;

    background-color: white;
}

.contractortakeoff-groupdetails-individual-container{
    margin: 2%;
    padding: 2%;
    border: 1px solid lightgray;
    border-radius: 5px;

    background-color: white;
}

.contractortakeoff-groupdetails-modal-select-container{
    position: relative;
}

.contractortakeoff-groupdetails-trade-title{
    font-weight: bold;
}

.contractortakeoff-subsectiondetails-title{
}

.contractortakeoff-page-name-input{
    border-radius: 5px;
    border: 1px solid lightgray;
    outline: none;

    padding-left: 5px;
}

.contractortakeoff-page-name-input:focus{
    border: 1px solid #006efa;
}

.contractortakeoff-groupdetails-section-container{
    padding: 2%;
}

.contractortakeoff-groupdetails-subsections{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.contractortakeoff-subsectiondetails-header{
    padding: 2%;
    background-color: whitesmoke;

    border-radius: 5px;

    display: grid;
    align-items: center;
    grid-template-columns: 11fr 1fr;
}

.contractortakeoff-subsectiondetails-shapes{
    padding: 2%;
}

.contractortakeoff-subsectiondetails-header:hover{
    cursor: pointer;
}

.contractortakeoff-groupdetails-subtrades-container{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.contractortakeoff-subsectiondetails-title-container{
    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;
    gap: 0px;

    width: fill-available;
}

.contractortakeoff-groupdetails-title-container{
    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;
    gap: 0px;

    width: fill-available;
}

.contractortakeoff-groupdetails-modal-choice-buttons{
    margin-top: 5%;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.floorplan-zoom-container{
    /**/
    position: absolute;
    top: 1%;
    right: 0%;
    z-index: 99999;
}

.contractortakeoff-pdfpage-header{
    padding-top: 2%;
    padding-bottom: 2%;
}

.contractortakeoff-pdfpage-container{
    max-height: 70vh;
    overflow: scroll;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;

    padding: 2%;

    border: 1px solid lightgray;
    border-radius: 5px;
}

.contractortakeoff-pdfpage-selected{
    border: 2px solid #006efa;
    box-shadow: 0px 0px 5px 0px #006efa;
    border-radius: 5px;
}

.contractortakeoff-pdfpage{
    border-radius: 5px;

    border: 2px solid white;
}

.contractortakeoff-pdfpage-canvas-container{
    width: 100%;

    padding: 2%;

    border-radius: 5px;
}

.contractortakeoff-pdfpage-canvas-container :hover{
    cursor: pointer;
}

.contractortakeoff-pdfpage-canvas{
    width: 100%;
}