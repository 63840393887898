.projectbids-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.projectbids-title-container{
    margin-bottom: 20px;
}

.projectbids-title{
    font-weight: bold;
    font-size: 36px;
}

.projectbids-biddetails-container{
    padding: 2%;
}

.projectbids-section-container{
    width: 120%;
    margin-left: -10%;
    border-radius: 5px;
    background-color: white;
    margin-top: 2%;

    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
}

.projectbids-extra-section-container{
    width: 120%;
    margin-left: -10%;
    border-radius: 5px;
    background-color: white;
    margin-top: 2%;
    padding: 2%;

    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
}

.projectbids-subsection-container{
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.projectbids-subsection-header{
    padding: 0.5% 2% 0.5% 2%;

    background-color: #EEF4FF;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.projectbids-subsection-header:hover{
    cursor: pointer;

    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.projectbids-item-desc{
    width: 50%;
}

.projectbids-subsection-sow-bidcontainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0px;
    padding-left: 1%;
    padding-right: 1%;

    padding-bottom: 2%;
}

.projectbids-item-bidscontainer{
}

.projectbids-subsection-bidcontainer{
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    gap: 10px;
    padding-top: 10px;
}

.projectbids-subsection-preview-bidcontainer{
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    gap: 10px;
}

.projectbids-subsection-biddetails-titles{
    padding-left: 10%;
    font-size: small;
    line-height: 24px;
}

.projectbids-section-contractors{
    margin-top: 2%;
    margin-bottom: 1%;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    gap: 10px;
}

.projectbids-subsection-title{
    font-weight: bold;
}

.projectbids-subsection-bidlable{
    font-style: italic;
    font-size: small;
    line-height: 24px;
    padding-left: 2%;
}

.projectbids-item-bids{
    padding-left: 30%;
    padding-right: 30%;
}

.projectbids-section-contractor{
    font-weight: bold;
    text-align: center;
}

.projectbids-extra-section{
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    gap: 10px;
    padding-bottom: 2%;
}

.projectbids-extra-item{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.projectbids-extra-contractors{
    padding-left: 35%;
    display: flex;
    flex-direction: row;
    padding-bottom: 2%;
    gap: 10px;
}

.projectbids-extra-section-title{
    width: 35%;
    padding-left: 5%;
}

.projectbids-extra-section-content{
}

.projectbids-item-bidtotal{
    background-color: whitesmoke;
}

.projectbids-subsection-bidtotal-lable{
    color: #006AFF;
}

.projectbids-section-title{
    font-weight: bold;
    font-size: 20px;
    color: #006AFF;
}

.projectbids-selectbutton{
    margin-left: 10px;
}

.projectbids-biddetails{
    margin-bottom: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
}

.projectbids-extra-title{
    font-weight: bold;
    font-size: 20px;
    color: #006AFF;
}

.projectbids-section-total{
    color: #006AFF;
    font-weight: bold;
}

.projectbids-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
}

.projectbids-total-title{
    font-size: 20px;
    color: #006AFF;
}

.projectbids-subsection-showdetails{
    color: gray;
}

.projectbids-subsection-showdetails:hover{
    cursor: pointer;
    color: black;
}

.projectbids-total-section{
    color: #006AFF;
}

.projectbids-subsection-item{
    font-size: small;
}

.projectbids-subsection-item-changed{
    font-size: small;
    color: orange;
}

.projectbids-subsection-item-added{
    font-size: small;
    color: green;
}

.projectbids-subsection-item-deleted{
    font-size: small;
    color: #FFCCCB;
    color: lightgray;
    text-decoration: line-through;
}

.projectbids-extra-desc{
    font-size: small;
    white-space: pre-line; 
}

.projectbids-subsection-preview-total{
    padding-left: 30%;
    padding-right: 30%;
}

.projectbids-section-header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;

    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;

    padding: 2%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
}

.projectbids-section-header:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.projectbids-subsection-details{
    padding-bottom: 1%;
}

.projectbids-item-extradesc{
    padding-left: 5%;
    padding-right: 5%;
    font-size: small;
    white-space: pre-line;
    line-height: 22px;
}

.projectbids-subsection-quantity{
    font-size: small;
    line-height: 22px;
}

.projectbids-sidenav-container{
    position: fixed;
    z-index: 10000;

    top: 20vh;
    right: 0px;
    padding-right: 10px;

  
    max-height: calc(70vh);
    overflow: auto;

    font-size: small;

    text-align: right;
}

.projectbids-sidenav-container ul{
    list-style: none;

    max-width: 13vw;
}

.projectbids-subsection-bid-row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    padding-bottom: 2%;
}

.projectbids-subsection-bid-rows{
    padding-left: 1%;
    padding-right: 1%;
}

/*take make the grackground color of every odd child of projectbids-subsection-bid-rows whitesmoke*/
.projectbids-subsection-bid-row:nth-child(odd){
    background-color: whitesmoke;
}

.projectbids-subsection-bid-row-total{
    font-weight: bold;
}

.projectbids-addbid-input{
    border-radius: 5px;
    outline: none;
    width: 30%;
    resize: none;
    padding-left: 10px;
}

.projectbids-addbid-input:hover{
    background-color: aliceblue;
}

.projectbids-addbid-input:active, .projectbids-addbid-input:focus{
    border-color: #006AFF;
}

.projectbids-biddetails-add-bids-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: -2%;
}

.projectbids-showsections{
    width: 110%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
}

.projectbids-trade-total{
    color: #006AFF;
    font-weight: bold;
    text-align: center;
}

.projectestimates-section-details-container{
    padding: 2%;
}