.contractorchangeorderbid-subsection-header{
    position: relative;

    display: grid;
    grid-template-columns: 1fr 15fr 6fr 5fr;
    align-items: center;

    padding: 2%;
    padding-top: 1.25%;
    padding-bottom: 1.25%;

    border-radius: 5px;

    width: 100%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.contractorchangeorderbid-subsection-header:hover{
    cursor: pointer;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.4);
}

.contractorprojectchangeordersubtrades-trade-header{
    position: relative;

    display: grid;
    grid-template-columns: 1fr 20fr 4fr 1fr;
    align-items: center;

    padding: 2%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    border-radius: 5px;

    width: 100%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.contractorprojectchangeordersubtrades-trade-header:hover{
    cursor: pointer;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.4);
}

.contractorprojectchangeorder-options-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    margin-top: 2%;
}

.generatecontract-warrantyitem-desc-deleted{
    color: red;
    text-decoration: line-through;

    background-color: #FFEEED;
}

.generatecontract-warrantyitem-desc-deleted:hover{
    background-color: #FFE6E5;
}

.generatecontract-warrantyitem-desc-added{
    background-color: #DDFADA;

    color: green;
}

.generatecontract-warrantyitem-desc-added:hover{
    background-color: #CBF7C7;
}

.generatecontract-warrantyitem-desc-added:focus{
    background-color: #CBF7C7;
}

.contractorprojectchangeordersubtrades-trade-header-changed{
    border: 1px solid orange;

    box-shadow: 0px 2px 4px 0px #FED8B1;
}

.generatecontract-warrantyitem-desc-changed{
    color: orange;

    background-color: #FFFFE0;
}

.generatecontract-warrantyitem-desc-changed:hover{
    background-color: #FFFFB6;
}

.generatecontract-warrantyitem-desc-changed:focus{
    background-color: #FFFFB6;
}

.contractorchangeordersubtrades-subtrade-header{
    display: grid;
    grid-template-columns: 1fr 20fr 6fr 4fr 1fr;
    align-items: center;
    gap: 10px;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.contractorchangeordersubtrades-subtrade-header:hover{
    cursor: pointer;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.4);
}

.contractorchangeordersubtrades-subtrade-options{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.contractorchangeordersubtrades-subtrade-move{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
}