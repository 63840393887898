.projectprofile-section-desc{
    border-radius: 5px;
    border: 1px solid lightgray;
    outline: none;

    padding: 10px;

    width: 100%;

    background-color: #F6F6FA;
}

.projectprofile-section-desc:hover{
    background-color: aliceblue;
}

.projectprofile-section-desc:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.projectprofile-section-size{
    border-radius: 5px;
    border: 1px solid lightgray;
    outline: none;

    padding: 5px;

    width: 125px;

    background-color: #F6F6FA;
}

.projectprofile-section-size:hover{
    background-color: aliceblue;
}

.projectprofile-section-size:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.projectprofile-section-body-container{
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 2%;

}

.editprojectdetail-section-body{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.projectprofile-section-body{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    align-items: start;
}

.projectprofile-section-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.projectprofile-section-info-hide{
    color: gray;
    text-align: right;
}

.projectprofile-section-info-hide:hover{
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.projectprofile-section-info{

}

.projectprofile-section-info-section{
    padding: 2%;
}

.projectprofile-section-info-section-body{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    font-style: italic;
}

.projectprofile-section-info-quality-img{
    width: 100%;
}

.projectprofile-section-quality{
    padding: 2%;
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 10px;
    align-items: center;
}