.openhouse-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.openhouse-title{
    font-weight: bold;
    font-size: 36px;
}

.openhouse-header{
    margin-bottom: 20px;
}

.openhouse-section-container{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    background-color: white;
    margin-top: 15px;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.openhouse-section-left{
    width: 50%;
    padding: 2%;
    padding-left: 8%;
    box-shadow: 15px 0px 10px -20px rgba(0,0,0,0.75);
}

.openhouse-section-right{
    width: 40%;
    padding: 2%;
    padding-left: 7%;
    padding-right: 0px;
    box-shadow: -15px 0px 10px -20px rgba(0,0,0,0.75);
}

.openhouse-section-date{
    width: fit-content;
    display: flex;
    flex-direction: row;

    border-radius: 5px;
    border: 1px solid lightgray;

    align-items: center;
    height: 35px;
}

.openhouse-section-date-details{
    padding: auto;
    width: 30px;
    max-width: 30px;
    min-width: 30px;
    height: stretch;
    padding: 4px 0;
    text-align: center;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #006AFF;
    color: white;
}

.openhouse-section-start{
    font-weight: bold;
    color: black;
}

.openhouse-section-end{
    margin-top: 2%;
    font-weight: bold;
}

.openhouse-section-datepicker{
    height: stretch;
    width: fit-content;
    border-left: 1px solid lightgray;
    background-color: whitesmoke;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.openhouse-section-datepicker div:first-child{
    border: none;
    padding-left: 5px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.openhouse-section-timerange{
    display: flex;
    flex-direction: row;
    height: 35px;

    border-radius: 5px;
    border: 1px solid lightgray;
}

.openhouse-section-timerangepicker{
    height: stretch;
    width: fit-content;
    border-left: 1px solid lightgray;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-right: 5px;
}

.openhouse-section-timerangepicker div:first-child{
    border: none;
    padding-left: 5px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.openhouse-section-timepicker{
    height: stretch;
    width: fit-content;
    background-color: whitesmoke;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.openhouse-section-timepicker div:first-child{
    border: none;
    padding-left: 5px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.openhouse-section-timepicker-range{
    color: black !important;
}

.openhouse-section-timepicker-range *{
    color: black !important;
}

.react-time-picker__inputGroup__amPm{
    appearance: none;
    background-image: url('');
}

.react-timerange-picker__inputGroup__amPm{
    appearance: none;
    background-image: url('');
}

.openhouse-section-time{
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.openhouse-section-deletebutton{
    margin-left: 3%;
}

.contractoropenhouse-cancelbutton{
    height: 40px;
    margin: auto;
}

.openhouse-section-title{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.openhouse-slot{
    height: 35px;
    border-radius: 4px;
    border: 1px solid lightgray;
    width: fit-content;

    display: flex;
    flex-direction: row;
}

.openhouse-slot-timepicker div:first-child{
    border: none;
}

.openhouse-slot-timepicker{
    padding-left: 5px;
    padding-right: 5px;

    background-color: white;
    border-radius: 3px;
}

.openhouse-slot-deletebutton{
    margin-left: 3%;
}

.openhouse-slot-conatiner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    margin-top: 10px;
    gap: 5%;
}

.openhouse-section-timepicker-published *{
    color: black;
}

.openhouse-published-slot-timerangepicker div:first-child{
    border: none;
    background-color: inherit;
    border: none;
    outline: none;
    color: black;
}

.openhouse-published-slot-timerangepicker{
    background-color: inherit;
    border: none;
    outline: none;
    color: black;
}

.openhouse-published-slot-timerangepicker *{
    color: black;
}

.openhouse-published-section-container{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    background-color: white;
    border-top: 3px solid #006AFF;
    margin-top: 15px;

    width: 60%;

    padding: 10px;
    padding-bottom: 20px;

    margin-left: auto;
    margin-right: auto;
}

.openhouse-published-slot-container{
    padding-left: 20px;
}

.openhouse-published-slot{
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
}

.openhouse-clear-icon:hover{
    color: #e56b6f;
}

.openhouse-slot-confirm-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.openhouse-reject-button{
    background-color: #e56b6f;
    border-radius: 5px;
    outline: none;
    border: 1px solid black;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.openhouse-reject-button:hover{
    background-color: red;
}
