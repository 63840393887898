.architectdashboard-question-container{
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 2%;

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.architectdashboard-question-link{
    text-decoration: none;
    color: black;
}

.architectdashboard-questions{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 10px 10px;
}

.architectviewquestion-input-price{
    width: 100px;
    margin-bottom: 1%;
}

.architectviewquestion-detail{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.architectviewquestion-file{
    margin-bottom: 2%;
}