.contractorbidtrade-trade-subtrades-container{
    padding: 2%;
}

.contractorbidtrade-trade-addsubtrade-container{
    margin-top: 2%;
}

.contractorbid-addtrade-container{
    margin-top: 2%;
}

.contractorbid-addtrade-title{
    font-weight: bold;
    font-size: 20px;
}

.contractorbid-addtrade-select{
    width: 150px;

    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
  
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    transition-duration: 0.3s;
    
    height: 36px;
}

.contractorbid-addtrade-select:hover{
    cursor: pointer;
}

.contractorbidtrade-trade-options:hover{
    cursor: pointer;
    color: #006afe;
}

.contractorbidtrade-trade-options-title{
    font-weight: bold;
    font-size: 20px;
}

.contractorbidtrade-trade-options-container{
    margin-bottom: 5%;
}

.contractorbidtrade-trade-options-desc{
    width: 80%;
}

.contractorbidtrade-trade-options-buttons-container{
    padding: 2%;
}

.contractorbid-subsection-cost-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contractorbid-subsection-cost-header-right{
    float: right;
}

.contractorbid-subtrade-header{
    display: grid;
    grid-template-columns: 25fr 8fr 5fr 1fr;
    align-items: center;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;

    background-color: #EEF4FF;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

    position: relative;
}

.contractorbid-subtrade-header:hover{
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.contractorbidtrade-options-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

.contractorbidtrade-expand-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.contractorbidtrade-section-title{
    font-weight: bold;
    font-size: 20px;
    color: #006afe;

    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;
}

.contractorbidtrade-section-header{
    position: relative;

    display: grid;
    grid-template-columns: 1fr 15fr 4fr 1fr;
    align-items: center;

    padding: 2%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
}

.contractorbidtrade-section-header{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorbidtrade-section-header:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.contractorbidtrade-section-comment-container{
    position: absolute;
    left: 102%;
    top: 0%;
    width: 28%;

    background-color: white;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    padding: 5px;

    padding-bottom: 0px;

    z-index: 998;
}

.contractorbidtrade-section-comment-container:hover{
    cursor: default;
}

.contractorbidtrade-trade-comment{
    background-color: white;

    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 2%;
    padding-bottom: 0px;

    width: 100%;

    margin-top: 5px;
}

.contractorbidtrade-trade-comment-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contractorbidtrade-trade-comment-input{
    width: 100%;

    border-radius: 5px;
    outline: none;
    border: 1px solid lightgray;

    padding: 2%;

    background-color: #F6F6FA;

    margin-bottom: 0px;

    margin-top: 5px;
}

.contractorbidtrade-trade-comment-input:hover{
    background-color: aliceblue;
}

.contractorbidtrade-trade-comment-input:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.contractorsow-subtrade-comments-container{
    position: absolute;
    left: 105%;
    top: 0%;
    width: 28%;

    background-color: white;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    padding: 5px;

    padding-bottom: 0px;

    z-index: 998;
}

.contractorsow-subtrade-comments-container:hover{
    cursor: default;    
}

.contractorsow-subtrade-comment-icon{
    position: absolute;
    left: 105%;
    top: 25%;

    white-space: nowrap;
    font-size: small;
}

.contractorsow-subtrade-comment-icon:hover{
    cursor: pointer;
    color: #006afe;
}

.contractorbidtrade-trade-comment-add{
    margin-top: 10px;
}

.contractorbidtrade-sow-options-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}