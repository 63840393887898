.projectdetail-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.projectdetail-title{
    font-weight: bold;
    font-size: 36px;
}

.projectdetail-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectdetail-project-status{
    color: #006AFF;
}

.projectdetail-projecttimeline-container{
    position: relative;
    border-radius: 5px;
    margin-top: 2%;
}

.projectdetail-projecttimeline-progressbar-background{
    background-color: lightgray;

    position: relative;
    top: 14px;
    height: 10px !important;
}

.projectdetail-projecttimeline-progressbar{
    position: absolute;
    width: 0%;

    top: 19px;
    height: 10px !important;
    background-color: lightblue;

    z-index: 1;
}

.projectdetail-projecttimeline-stepcontainer{
    display: flex;
    flex-direction: column;
    gap: 0px;
    border: 1px solid gainsboro;
    width: 100%;

    border-radius: 5px;
}

.projectdetail-projecttimeline-stepcontainer > div:not(:last-child){
    border-bottom: 1px solid gainsboro;
}

.projectdetail-projecttimeline-ministep{
    padding: 5%;
}

.projectdetail-projecttimeline-ministep:hover{
    background-color: #efefef;
    cursor: pointer;
}

.projectdetail-projecttimeline-stepcontainer > div:first-child :hover{
    border-radius: 4px 4px 0px 0px;
}

.projectdetail-projecttimeline-stepcontainer > div:last-child :hover{
    border-radius: 0px 0px 4px 4px;
}

.projectdetail-projecttimeline-progress-milestone{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    width: 30%;
}
    
.projectdetail-projecttimeline-ministeps{
    width: 100%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;

    background-color: white;
}

.projectdetail-status-title{
    font-weight: bold;
    margin-top: 2%;
}

.projectdetail-status-container{
    border-radius: 5px;
    background-color: white;
    padding: 2%;
    margin-bottom: 4%;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
}

.projectdetail-status-name{
    color: #006AFF;
    font-size: 20px;
}

.projectdetail-status-link{
    font-size: 18px;
    text-decoration: none;
    color: #006AFF;
}

.projectdetail-status-link:hover{
    text-decoration: underline;
}

.dashboard-project-details-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.projectdetail-details-browse-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.projectdetail-status-addedcontractors{
    margin-top: 1%;
    margin-bottom: 1%;
}

.projectdetail-requestedbids-contractor-container{
    width: fit-content;
}

.projectdetail-status-addedcontractors-link{
    text-decoration: none;
    color: #006AFF;
}

.projectdetail-status-addedcontractors-link:hover{
    text-decoration: underline;
}

.projectdetail-step-container{
    margin-top: 2%;
    border-radius: 5px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.projectdetail-step-header{
    padding: 2%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 5px;

    background-color: aliceblue;
}

.projectdetail-step-header-container{
    background-color: aliceblue;
    border-radius: 5px;
}

.projectdetail-step-header-container:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px 0px;
}

.projectdetail-step-body{
    padding: 4%;
    padding-top: 0%;
    padding-bottom: 2%;
}

.projectdetail-step-overview{
    padding: 1% 4% 1% 4%;
}

.projectdetail-substep-container{
    border-radius: 5px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 5px 0px;
    margin-top: 2%;
}

.projectdetail-substep-header{
    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.projectdetail-step-title{
    font-weight: bold;
    font-size: 20px;
    width: 50%;
}

.projectdetail-substep-title{
    font-weight: bold;
    font-size: 20px;
}

.projectdetail-substep-body{
    padding: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.projectdetail-substep-title-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.projectdetail-substep-optional{
    color: gray;
    border-radius: 5px;
    background-color: whitesmoke;
    padding: 2px 15px 2px 15px;
    font-size: small;
    text-align: center;
}

.projectdetail-substep-warning{
    color: rgb(138, 37, 37);
    font-size: 18px;
}

.projectdetails-publish-warning{
    color: rgb(138, 37, 37);
    font-weight: bold;
}

.projectdetail-substep-requestbid-container{
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 40px;
    margin-bottom: 2%;
}

.projectdetail-requestedbids-container{
    display: grid;
    grid-template-columns: 2fr 3fr;
}

.projectdetail-substep-favorited-container{
    height: fit-content;
    padding: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 5px 0px;
}

.projectdetail-substep-button-container{
    display: flex;
    font-display: row;
    align-items: center;
    gap: 15px;
}

.projectdetail-step-status-done{
    border-radius: 5px;
    padding: 2px 15px 2px 15px;
    background-color: rgb(173, 222, 255);

    box-shadow: rgb(0 0 0 / 15%) 0px 0px 5px 0px;
}

.projectdetail-step-status-notdone{
    border-radius: 5px;
    padding: 2px 15px 2px 15px;
    background-color: whitesmoke;

    box-shadow: rgb(0 0 0 / 15%) 0px 0px 5px 0px;
}

.projectdetail-step-header-disabled{
    background-color: whitesmoke;
}

.projectdetail-step-header-container-disabled{
    background-color: whitesmoke;
}

.projectdetail-step-header-container-disabled:hover{
    cursor: not-allowed;
    box-shadow: none;
}

.projectdetail-step-header-disabled:hover{
    cursor: not-allowed;
    box-shadow: none;
}

.projectdetail-step-number{
    display: inline-block;
    text-align: center;
    width: 30px;
    background-color: #96c2ff;
    border-radius: 50px;
    margin-right: 3%;
}

.projectdetail-step-number-disabled{
    background-color: lightgray;
}

.projectdetail-step-container-active{
    box-shadow: #96c2ff 0px 0px 4px 0px;
}

.projectdetail-substep-requestbid-message{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}