.projectsign-sigcanvas{
    border: 2px solid black;
}

.projectsign-container{
    display: grid;
    grid-template-columns: 7fr 3fr;
    height: 94vh;
}

.projectsign-pdf{
    height: 94vh;
    width: stretch;
}

.projectsign-left{
    margin-top: 6vh;
    background-color: gainsboro;
}

.projectsign-right{
    margin-top: 6vh;
    padding: 4%;
}