.support-background{
    /*background-image: url("https://bobyard-public-images.s3.us-west-2.amazonaws.com/support-background+(1).jpeg");
    background-size: cover;
    background-repeat: no-repeat;*/

    position: absolute;

    z-index: 1;

    width: 300%;
    margin-left: -70%;
    top: 15%;
}

.support-container{
    height: 100vh;

    background-color: #043873;

    overflow: hidden;

    position: relative;
}

.support-message-container{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);

    padding: 2%;

    width: 40%;

    margin-top: 5%;
    margin-left: 30%;
    margin-bottom: 5%;

    z-index: 2;

    position: relative;
}

.support-message-desc{
    width: 80%;

    margin-bottom: 2%;
}

.support-message-desc-info{
    margin-bottom: 5%;
}

.support-message-input-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    gap: 2%;

    width: 100%;
}

.support-message-input{
    border-radius: 5px;

    outline: none;
    border: 1px solid black;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    width: 100%;
}

.support-message-input:hover{
    background-color: aliceblue;
}

.support-message-input:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.support-message-message{
    width: 100%;
    margin-top: 3%;
    margin-bottom: 1%;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    border-radius: 5px;
    outline: none;
    border: 1px solid black;
}

.support-message-message:hover{
    background-color: aliceblue;
}

.support-message-message:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.support-message-error{
    text-align: right;
}

.support-message-sent{
    color: #006AFF;
}

.support-footer-container{
    display: flex;
    align-items: center;
    gap: 10px;

    font-weight: bold;
    position: absolute;
    right: 10px;
    bottom: 10px;
}

@media only screen and (max-width: 500px) {
    .support-message-container{
        width: 80%;
        margin-left: 10%;

        padding: 4%;
    }

    .support-message-input-container{
        display: block;
    }

    .support-message-input{
        width: 100%;
        margin-top: 3%;
    }

    .support-background-img{
        width: 200%;
        margin-left: -20%;
    }
}