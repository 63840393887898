.contractorproject-sidebar-container{
    position: fixed;
    left: 0;
    top: 0;

    height: 100vh;
    width: 17vw;
    background-color: white;

    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);

    padding-top: 7%;

    z-index: 100;

    overflow-y: scroll;
    overflow-x: hidden;
}

.contractorproject-sidebar-container-files{
    position: fixed;
    right: 0;
    top: 0;

    height: 100vh;
    width: 17vw;
    background-color: white;

    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);

    padding-top: 8%;

    z-index: 100;
}

.contractorproject-sidebar-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 0px;
}

.contractorproject-sidebar-address{
    padding: 5%;
    padding-left: 10%;
    padding-right: 10%;
}

.contractorproject-sidebar-header-title{
    font-size: 1.5rem;
    font-weight: bold;
}

.contractorproject-sidebar-close{
    font-weight: bold;
    border: 1px solid lightgray;

    border-radius: 10px;
    width: 30px;
    height: 30px;

    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    justify-self: center;
    
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
}

.contractorproject-sidebar-close:hover{
    cursor: pointer;
    color: #0066ff;

    background-color: whitesmoke;

    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
}

.contractorproject-sidebar-icon-container{
    position: absolute;
    left: 2%;
    top: 15%;
    font-size: 20px;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.contractorproject-sidebar-icon-container-files{
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.01);
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
  
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    transition-duration: 0.3s;  
}

.contractorproject-sidebar-icon-container-files:hover{
    cursor: pointer;
    color: #0066ff;

    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
}

.contractorproject-sidebar-icon-container:hover{
    cursor: pointer;
    color: #0066ff;
}

.contractorproject-sidebar-section-item-link{
    text-decoration: none;
    color: black;
}

.contractorproject-sidebar-section-item-link:hover{
    color: black;
}

.contractorproject-sidebar-section-title{
    display: grid;
    grid-template-columns: 1fr 7fr;

    font-weight: bold;

    padding: 5%;
    padding-left: 10%;
}

.contractorproject-sidebar-section-title:hover{
    cursor: pointer;
    color: #0066ff;
}

.contractorproject-sidebar-section{
    width: 100%;

    margin-bottom: 5%;
}

.contractorproject-sidebar-section-item-current{
    background-color: lightskyblue;
    padding: 2%;
    padding-left: 20%;
}

.contractorproject-sidebar-section-item{
    padding: 2%;
    padding-left: 20%;
}

.contractorproject-sidebar-section-item:hover{
    background-color: gainsboro;
    cursor: pointer;
}

.contractorproject-sidebar-section-item-disabled{
    padding: 2%;
    padding-left: 20%;
    color: gray;
}

.contractorproject-sidebar-section-item-disabled:hover{
    cursor: disabled;
}