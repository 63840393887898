.dropdownmenu{
    display: inline-block;    
    position: relative;
}

.dropdownmenu-disabled{
    padding-left: 15px;
    padding-right: 15px;
}

.dropdownmenu-disabled:hover{
    cursor: not-allowed;
}

.dropdownmenu-disabled-small{
    height: 32px;
    padding-top: 3px;
    padding-left: 12px;
}

.dropdownmenu-disabled-small:hover{
    cursor: not-allowed;
}

.dropdownmenu-header-check{
    height: 20px;
    margin-left: 5px;
    margin-top: -3px;
    vertical-align: middle;
    color: gray;
}

.dropdownmenu-header{
    display: flex;
    align-items: center;
    padding-left: 15px;
    height: fill-available;
    overflow: hidden;
}

.dropdownmenu-header-measure-title{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    text-overflow: ellipsis;
}

.dropdownmenu-header-small{
    height: 32px;
    padding-top: 3px;
    padding-left: 12px;
}

.dropdownmenu-menucontainer{
    position: absolute;
    top: 110%;
    width: 50vw;
    left: 0;
    max-height: 65vh;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    z-index: 1000;
    box-shadow: rgba(45, 32, 32, 0.3) 0px 2px 4px 0px;
}

.dropdownmenu-menucontainer-takeoff{
    position: absolute;
    top: 110%;
    width: 50vw;
    left: -200px;
    max-height: 65vh;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    z-index: 1000;
    box-shadow: rgba(45, 32, 32, 0.3) 0px 2px 4px 0px;
}

.dropdownmenu-menu-container{
    max-height: 59vh;
    overflow: scroll;
    border-radius: 5px;
    padding: 0px;
}

.dropdownmenu-menu-contents{
    padding: 2%;
    padding-bottom: 0px;
    padding-right: 3%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    row-gap: 20px;
}

.dropdownmenu-menu-grouptitle{
    color: #006AFF;
    padding-left: 10px;
}

.dropdownmenu-menu-item-active{
    padding: 3px;
    padding-left: 10px;
    border-radius: 3px;
    background-color: #006AFF;
    color: white;
    font-size: small;
}

.dropdownmenu-menu-item{
    font-size: small;
    padding: 3px;
    padding-left: 10px;
    border-radius: 3px;
}

.dropdownmenu-menu-item:hover{
    background-color: #DEEBFF;
    cursor: pointer;
}

.dropdownmenu-search-container{
    padding: 1%;
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
}

.dropdownmenu-search-input{
    height: 32px;

    background-color: white;
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204);
  
    padding-left: 8px;
    padding-right: 8px;

    background-color: #F6F6FA;
}

.dropdownmenu-search-input:hover{
    background-color: #EEF4FF;
}

.dropdownmenu-header-label{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    white-space: nowrap;
    overflow: hidden;
}

.dropdownmenu-header-label-text{
    max-width: 250px;
    overflow: hidden;

    text-overflow: ellipsis;
}