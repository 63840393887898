.contractorviewcontracts-left{
    margin-top: 6vh;
}

.contractorviewcontracts-right{
    padding: 5%;
    margin-top: 6vh;;
}

.contractorviewcontracts-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.contractorviewcontracts-title{
    font-weight: bold;
    font-size: 20px;
}

.contractorviewcontracts-files{
    margin-bottom: 20px;
}

.contractorviewcontracts-files:hover{
    font-weight: bold;
    cursor: pointer;
}

.contractorviewcontracts-files-current{
    font-weight: bold;
}

.contractorviewcontracts-container{
    display: grid;
    grid-template-columns: 3fr 7fr;
    height: 100vh;
    overflow-y: hidden;
}

.contractorviewcontracts-files-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    padding: 2%;
}