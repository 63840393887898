.contractorsinfo-container {
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 10%;
    margin-top: 10%;
}

.contractorsinfo-title {
    font-size: 65px;
    font-weight: 800;
    margin-bottom: 20px;
    line-height: 70px;

    padding-left: 20%;
    padding-right: 20%;
}

.contractorsinfo-header-title {
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 20px;
    line-height: 70px;
}

.contractorsinfo-header-link {
    color: black;
    text-decoration: none;
}

.contractorsinfo-header-link:hover {
    color: black;
}

.contractorsinfo-background {
    background-image: url("https://bobyard-public-images.s3.us-west-2.amazonaws.com/contractor-background.png");
    background-size: cover;
}

.contractorsinfo-header-desc {
    font-size: 20px;
}

.contractorsinfo-sow-desc {
    font-size: 20px;
    width: 80%;
}

.contractorsinfo-desc-container {
    display: flex;
    flex-direction: row;
    gap: 10%;
    align-items: center;
    justify-content: space-between;
}

.contractorsinfo-image {
    height: 300px;
    border: 2px solid gray;
    border-radius: 5px;
    box-shadow: 5px 5px 5px gray;
}

.contractorsinfo-sow-container {
    margin-top: 20%;
}

.contractorsinfo-sow-iframe {
    margin-top: 4%;
    width: 80%;
    height: 50vh;
    margin-left: 10%;
    margin-right: 10%;

    border-radius: 5px;
    box-shadow: 5px 5px 5px gray;
}

.contractorsinfo-contract-container {
    margin-top: 30%;
    display: flex;
    flex-direction: row;
    gap: 10%;
    align-items: center;
    justify-content: space-between;
}

.contractorsinfo-footer {
    margin-top: 20%;
    text-align: center;
}

.contractorsinfo-header-waitlist {
    text-align: center;
    margin-top: 3%;
}

.contractorsinfo-mobile-picture-container {
    margin-bottom: 10%;
    display: none;
}

@media only screen and (max-width: 500px) {
    .contractorsinfo-title {
        padding-top: 20%;

        padding-left: 10%;
        padding-right: 10%;
        font-size: 36px;
        line-height: 40px;
    }

    .contractorsinfo-header-title {
        line-height: 40px;
    }

    .contractorsinfo-background {
        overflow: hidden;
    }

    .contractorsinfo-image {
        display: none;
    }

    .contractorsinfo-sow-iframe {
        display: none;
    }

    .contractorsinfo-mobile-iframe {
        width: 100%;
        margin-top: 4%;
        height: 50vh;

        border-radius: 5px;
        box-shadow: 5px 5px 5px gray;
    }

    .contractorsinfo-contract-container {
        display: block;
        margin-top: 15%;
    }

    .contractorsinfo-mobile-picture-container {
        display: block;

        margin-top: 20vh;
    }

    .contractorsinfo-mobile-image {
        width: 100%;
        height: auto;
        border: 2px solid gray;
        border-radius: 5px;
        box-shadow: 5px 5px 5px gray;
    }
}

.contractorsinfo-header-title {
    line-height: 40px;
}

.contractorlanding-testimonial-carousel {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.contractorlanding-testimonial {
    text-align: center;
    margin-bottom: 20px;
    padding-left: 5%;
    padding-right: 5%;
}

.contractorlanding-testimonial p {
    font-size: 18px;
    color: #333;
}

.contractorlanding-testimonial p:last-child {
    font-weight: bold;
    color: #777;
}

.contractorlanding-carousel-buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.contractorlanding-carousel-buttons button {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin: 0 5px;
    transition: background-color 0.3s ease;
}

.contractorlanding-carousel-buttons button:hover {
    background-color: #0056b3;
}

.contractorlanding-testimonial-carousel-header{
    font-size: 36px;
    font-weight: 800;
}

.contractorsinfo-testimonial-title{
    text-align: center;
}