.adminprojectcomment-container{
    padding-top: 5%;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 5%;
}

.adminprojectcomment-sowbutton-container{
    margin-bottom: 1%;
}

.adminprojectcomment-sow-container{
    padding-bottom: 2%;
}

.adminprojectcomment-sow-container-hidden{
    display: none;
}

.adminprojectcomment-sow-section{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
    border-top: 3px solid #006AFF;
    background-color: white;
    margin-bottom: 2%;
}

.adminprojectcomment-sow-section-title{
    font-weight: bold;
    font-size: 20px;
    color: #006AFF;
    padding: 2%;    
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.adminprojectcomment-sow-section-title:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.adminprojectcomment-sow-subsection{
    margin-top: 2%;
}

.adminprojectcomment-sow-subsection-title{
    font-weight: bold;
}

.adminprojectcomment-sow-item{
    font-size: small;
    border: none;
    outline: none;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-left: 2px solid white;
}

.adminprojectcomment-title{
    font-weight: bold;
    font-size: 36px;
}

.adminprojectcomment-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.adminprojectcomment-header-details{
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.adminprojectcomment-sow-container{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
}

.adminprojectcomment-button-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.adminprojectcomment-dashboard-container{
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-top: 20px;
    gap: 2%;

    margin-bottom: 4%;
}

.adminprojectcomment-dashboard-sow-title{
    font-size: 20px;
    font-weight: bold;
}

.adminprojectcomment-dashboard-openhouse-container{
    border-radius: 5px;
    padding: 10px;
}

.adminprojectcomment-dashboard-files-container{
    border-radius: 5px;
    padding: 10px;
}

.adminprojectcomment-dashboard-openhouse-status{
    margin-top: 2%;

    background-color: white;
    border-radius: 5px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    height: 100px;
    min-width: 100px;
    max-height: 100px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
}

.adminprojectcomment-dashboard-openhouse-title{
    font-size: 20px;
    font-weight: bold;
}

.adminprojectcomment-dashboard-openhouse-change{
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
}

.adminprojectcomment-dashboard-openhouse-notsignedup{
    margin-top: 2%;

    background-color: white;
    border-radius: 5px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    height: 75px;
    min-width: 75px;
    max-height: 75px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
}

.adminprojectcomment-files-link{
    color: black;
    text-decoration: none;
}

.adminprojectcomment-files-link:hover{
    font-weight: bold;
    color: black;
}

.adminprojectcomment-files-container{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin-top: 2%;
    padding-left: 20px;
    padding-right: 20px;

    height: 75px;
    min-width: 75px;
    max-height: 75px;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;

    display: flex;
    align-items: center;
    gap: 10px;
}

.adminprojectcomment-timeline-progressbar-container{
    position: relative;
    background-color: white;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 15px;
    margin-top: 10px;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
}

.adminprojectcomment-timeline-progressbar-background{
    background-color: lightgray;

    position: relative;
    margin-left: 16.5%;
    margin-right: 16.5%;
    top: 14px;
    height: 10px !important;
}

.adminprojectcomment-timeline-progressbar{
    position: absolute;
    margin-left: 16.5%;
    margin-right: 16.5%;
    width: 0%;

    top: 19px;
    height: 10px !important;
    background-color: lightblue;

    z-index: 1;
}

.adminprojectcomment-timeline-step3-button{
    text-align: center;
}

.adminprojectcomment-timeline-container{
    padding-left: 10px;
    padding-right: 10px;
}

.adminprojectcomment-timeline-title{
    font-size: 20px;
    font-weight: bold;
}

.adminprojectcomment-sow-items-container{
    padding-bottom: 2%;
}

/* .cvp-addedconditionalsow{
    margin-top: 1%;
    padding-top: 1%;
    padding-bottom: 0px;
    padding-left: 3%;
    padding-right: 3%;

    border: 1px solid lightgray;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.cvp-addedconditionalsow:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
} */

.adminprojectcomment-sow-subsectiontitle{
    font-size: 18px;
    font-weight: bold;
}

/* .editprojectdetail-addedconditionalsows-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
} */

.adminprojectcomment-sow-subsection-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-left: 5%;
}

.adminprojectcomment-projectsubsection-container{
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding: 2%;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.adminprojectcomment-projectsubsection-container:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.adminprojectcomment-projectsubsection-heading-container{
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.adminprojectcomment-projectsubsection-heading-container:hover{
    cursor: pointer;
}
.adminprojectcomment-sow-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1%;
}

.adminprojectcomment-collapseall{
    margin-bottom: 0px;
    margin-top: 0px;
}

.adminprojectcomment-sow-subsection-body-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.adminprojectcomment-sow-section-desc-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.adminprojectcomment-sow-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1%;
}

.adminprojectcomment-showsections{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.adminprojectcomment-sow-title{
    font-weight: bold;
    font-size: 20px;
}

.adminprojectcomment-sow-subsection-body{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.adminprojectcomment-project-container{
    margin-top: 2%;
}

.homeownerbid-section-body-container{
    padding: 2%;
    padding-top: 1%;
    position: relative;
}

.adminprojectcomment-comment-container{
    position:absolute;
    left: -30%;
    width: 15vw;
}


.adminprojectcomment-sow-comment {
    background-color: white;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    border-radius: 5px;
    padding: 10px;
    margin: 1%;
}

.adminprojectcomment-comment-desc {
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 100%;
    resize: none;
}

.adminprojectcomment-comment-close {
    position: relative;
    float: right;
}

.adminprojectcomment-comment-close:hover{
    background-color: #F1F1F4;
}

