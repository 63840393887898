.contractorchangeorder-subsection-header{
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 1%;
    padding-left: 2%;
    padding-right: 3%;

    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
    
.contractorchangeorder-subsection-header:hover{
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.contractorchangeorder-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2%;
    gap: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 20px;

    background-color: #F6F6FA;
    border-color: #F6F6FA;
}

.contractorchangeorder-item:hover{
    background-color: #EEF4FF;
    border-color: #EEF4FF;
}

.contractorchangeorder-item div:nth-child(2){
    width: 100%;
}

.contractorchangeorder-item-deleted{
    background-color: #FFEEED;
}

.contractorchangeorder-item-deleted:hover{
    background-color: #FFE6E5;
}

.contractorchangeorder-item-desc-deleted{
    color: red;
    text-decoration: line-through;
}

.contractorchangeorder-item-desc-deleted:hover{
    color: red;
    background-color: #FFE6E5;
}

.contractorchangeorder-item-desc-deleted:focus{
    background-color: #FFE6E5;
}

.contractorchangeorder-item-added{
    background-color: #DDFADA;
}

.contractorchangeorder-item-added:hover{
    background-color: #CBF7C7;
}

.contractorchangeorder-item-desc-added{
    color: green;
}

.contractorchangeorder-item-desc-added:hover{
    color: green;
    background-color: #CBF7C7;
}

.contractorchangeorder-item-desc-added:focus{
    background-color: #CBF7C7;
}

.contractorchangeorder-item-changed{
    background-color: #FFFFE0;
}

.contractorchangeorder-item-changed:hover{
    background-color: #FFFFB6;
}

.contractorchangeorder-item-changed:focus{
    background-color: #FFFFB6;
}

.contractorchangeorder-item-desc-changed{
    color: orange;
}

.contractorchangeorder-item-desc-changed:hover{
    color: orange;
    background-color: #FFFFB6;
}

.contractorchangeorder-item-desc-changed:focus{
    background-color: #FFFFB6;
}

.contractorchangeorder-item-bringback{
    font-size: 12px;
    line-height: 12px;
    width: 12px !important;
    align-items: center;
    text-align: center;
}

.contractorchangeorder-item-bringback:hover{
    cursor: pointer;
    color: green;
}

.contractorchangeorder-subsection-deleted{
    border-color: lightcoral;

    background-color: #FFEEED;

    box-shadow: 0px 2px 2px 0px #FFEEED;
}

.contractorchangeorder-subsection-added{
    border-color: lightgreen;

    box-shadow: 0px 2px 2px 0px #CBF7C7;
}

.contractorchangeorder-subsection-changed{
    border-color: orange;

    box-shadow: 0px 2px 2px 0px #FED8B1;
}

.contractorchangeorder-subsection-undelete-button{
    align-items: center;
    text-align: center;

    border: none;

    background-color: inherit;
}

.contractorchangeorder-subsection-undelete-button:hover{
    cursor: pointer;
    color: green;
}

.contractorchangeorder-section-deleted{
    border: 1px solid lightcoral;

    box-shadow: 0px 2px 4px 0px #FFCCCB;
}

.contractorchangeorder-section-added{
    border: 1px solid lightgreen;

    box-shadow: 0px 2px 4px 0px #CBF7C7
}

.contractorchangeorder-section-changed{
    border: 1px solid orange;

    box-shadow: 0px 2px 4px 0px #FED8B1;
}

.contractorchangeorder-updown-container{
    display: grid;

    position: relative;
}