.expertreview-container{
    padding-top: 6vh;
    padding-left: 20%;
    padding-right: 20%;
}

.expertreview-title{
    font-size: 25px;
    margin-bottom: 2%;
    margin-top: 2%;
    color: #006AFF;
}

.exertreview-message-container{
    width: 60%;
    margin-left: 20%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 #0000004d;

    padding: 2%;

    margin-top: 5%;
}

.exertreview-input-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    gap: 2%;
}

.exertreview-message-input{
    width: 100%;
}

