.landing4-container {
    position: relative;
    /*Lexend Deca*/
    font-family: 'Lexend Deca', sans-serif !important;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    background-color: #FFFFFF;
}

.landing4-background {
    z-index: 1;
    position: absolute;
    top: 7vh;
    right: 5vw;
    overflow: hidden;
}

.landing4-background-image {
    width: 55vw;
    height: 90vh;
    object-fit: contain;
}

.landing4-content {
    z-index: 2;
    padding: 3% 10%;
}

.landing4-header {
    z-index: 2;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.landing4-header-logo-image {
    z-index: 10;

    height: 50px;
}

.landing4-header-menu {
    z-index: 3;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.landing4-header-menu-item {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;

    cursor: pointer;
}

.landing4-header-menu-item-link {
    color: black;
    text-decoration: none;
}

.landing4-header-menu-item:hover {
    color: #006AFF;
}

.landing4-header-menu-button {
    font-size: 18px;
    font-weight: 500;

    border-radius: 5px;
    background-color: #006AFF;
    color: white;
    border: 1px solid #006AFF;

    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

.landing4-header-menu-button:hover {
    cursor: pointer;
    background-color: rgb(13, 69, 153);
    border-color: rgb(13, 69, 153);
}

.landing4-body {
    z-index: 3;

    padding-top: 10%;
    height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.landing4-body-main {
    z-index: 3;

    display: flex;
    flex-direction: column;
    gap: 24px;
}

.landing4-body-title {
    font-size: 50px;
    line-height: 64px;
    font-weight: 700;
}

.landing4-body-subtitle {
    max-width: 400px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
}

.landing4-body-connect {
    z-index: 3;
}

.landing4-body-connect-button {
    padding-top: 1%;
}

.landing4-body-connect-title {
    max-width: 400px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
}

.landing4-body-backedby {
    z-index: 3;

    display: flex;
    flex-direction: column;

    gap: 6px;
}

.landing4-body-backedby-title {
    font-size: 14px;
    line-height: 23px;
    font-weight: 400;
}

.landing4-body-backedby-logos {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 30px;
}

.landing4-body-backedby-primary-logo-image {
    height: 29px;
}

.landing4-body-backedby-pear-logo-image {
    height: 32px;
}

.landing4-header-mobile-menu-popup {
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;

    background-color: #FCFCFF;
    padding: 3% 10%;
}

.landing4-header-mobile-menu {
    z-index: 4;
    display: none;
}

.landing4-header-mobile-menu-header {
    z-index: 3;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.landing4-header-menu-body {
    margin-top: 10vh;

    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.landing4-mobile-render {
    display: none;
}

/*media query at 900px*/
@media (max-width: 1000px) {
    .landing4-body-main {
        margin-top: 2vh;
        gap: 0px;
    }

    .landing4-body-title {
        font-size: 30px;
        line-height: 40px;
    }

    .landing4-body-connect-title {
        font-size: 16px;
        line-height: 24px;
    }

    .landing4-body-subtitle {
        font-size: 20px;
        line-height: 25px;
    }

    .landing4-header-mobile-menu {
        display: block;
    }

    .landing4-header-menu {
        display: none;
    }

    .landing4-background {
        display: none;
    }

    .landing4-body {
        padding-top: 0%;
    }

    .landing4-mobile-render {
        display: block;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        justify-content: space-between;
    }

    .landing4-mobile-render-image {
        width: 80vw;
        max-height: 50vh;
        object-fit: contain;
    }

    .landing4-body-connect {
        margin-top: 2vh;
    }

    .landing4-body-connect-button {
        margin-top: 1vh;
    }

    .landing4-body-backedby {
        margin-top: 6vh;
    }

    .landing4-header-menu-button {
        padding: 3px;
        padding-left: 20px;
        padding-right: 20px;

        font-size: 16px;
        line-height: 24px;
    }
}

@media (max-width: 540px) {
    .landing4-mobile-render {
        z-index: 1;
    }

    .landing4-header-logo-image {
        height: 40px;
    }

    .landing4-body-title {
        font-size: 30px;
        line-height: 40px;
    }

    .landing4-body-subtitle {
        font-size: 20px;
        line-height: 25px;
    }

    .landing4-body-backedby {
        margin-top: 2vh;
    }
}

@media (max-width: 400px) {
    .landing4-header-logo-image {
        height: 40px;
    }

    .landing4-body-title {
        font-size: 20px;
        line-height: 22px;
    }

    .landing4-body-subtitle {
        font-size: 14px;
        line-height: 18px;
    }
}