.career2-container {
    position: relative;
    /*Lexend Deca*/
    font-family: 'Lexend Deca', sans-serif !important;
    overflow-x: hidden;
    padding-bottom: 5%;

    background-color: #FCFCFF;
}

.career2-background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
}

.career2-background-image {
    width: 100vw;
    height: 100%;
    object-fit: cover;
}

.career2-body {
    padding-top: 80px;
}

.career2-body-main {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.career2-body-main-title {
    font-size: 50px;
    font-weight: 700;
    line-height: 64px;
}

.career2-body-main-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.career2-body-roles-selector {
    padding-top: 8%;

    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 90%;
    padding-left: 10%;
}

.career2-body-roles-selector-item {
    font-size: 24px;
    line-height: 50px;
    font-weight: 700;
    text-align: center;

    border-bottom: 2px solid rgb(0, 0, 0, 0.2);
}

.career2-body-roles-selector-item:hover {
    cursor: pointer;

    color: #006AFF;
}

.career2-body-roles-selector-item-active {
    color: #006AFF;

    border-bottom: 2px solid #006AFF;
}

.career2-body-roles {
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 96%;
    padding-left: 4%;

    min-height: 800px;
}

.career2-body-roles-header {
    padding-top: 4%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.career2-body-roles-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 50px;
    color: #006AFF;
}

.career2-body-roles-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.career2-body-roles-apply-button {
    font-size: 20px;
    font-weight: 500;

    border-radius: 5px;
    background-color: #006AFF;
    color: white;
    border: 1px solid #006AFF;

    padding: 5px;
    padding-left: 50px;
    padding-right: 50px;
    transition-duration: 0.3s;
}

.career2-body-roles-apply-button:hover {
    cursor: pointer;
    background-color: rgb(13, 69, 153);
    border-color: rgb(13, 69, 153);
}

@media (max-width: 1000px) {
    .career2-body-main {
        gap: 10px;
    }

    .career2-body-main-title{
        margin-top: 10%;

        font-size: 40px;
        line-height: 50px;
    }

    .career2-body-roles-selector-item {
        font-size: 18px;
        line-height: 40px;
    }

    .career2-body-roles-title {
        font-size: 20px;
        line-height: 26px;
    }
}

@media (max-width: 600px) {
    .career2-body-main{
        gap: 10px;
    }

    .career2-body-main-title{
        margin-top: 10%;

        font-size: 30px;
        line-height: 40px;
    }

    .career2-body-roles-selector-item {
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 5px;
    }

    .career2-body-roles-title {
        font-size: 18px;
        line-height: 30px;
    }

    .career2-body-roles-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}