.projectbids-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.projectbids-bids-container{
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2%;
}

.projectbids-bid{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: white;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
}

.addedbidlist-desc-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 75%;

    border-radius: 5px;
    padding: 2%;
    border: 1px solid lightgray;
    background-color: white;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
}