.projectsubsection-container{
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.projectsubsection-container-noeffects{
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 2px 0px;
}

/*.projectsubsection-container:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}*/

.projectsubsection-hr{
    height: 10px;
    width: 75%;
    margin-bottom: 0%;
    color: linear-gradient(to right, #2565AE, white);
    background-image: linear-gradient(to right, #2565AE, white);
}

.projectsubsection-choice{
    border: none;
    background-color: inherit;
    border-radius: inherit;
}

.projectsubsection-items-conatiner{
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-bottom: 2%;
}

/*.editprojectdetail-item-desc{
    width: 80%;
    border: none;
    outline: none;
    padding: 5px;
    border-left: 2px solid white;
    background-color: #F6F6FA;
    box-shadow: inset 0 0 2px gray;
}*/

.projectsubsection-itemup-button{
    height: 12px;
    font-size: 12px;
    line-height: 12px;
    width: 20px;
    align-items: center;
    text-align: center;
    padding: 0px;

    border: none;

    background-color: white;

    border-radius: 5px;
}

.projectsubsection-itemup-button:hover {
    font-weight: bold;
    cursor: pointer;
    background-color: whitesmoke;
}

.editprojectdetail-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2%;
    gap: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 20px;

    background-color: #F6F6FA;
    border-color: #F6F6FA;
}

.editprojectdetail-item:hover{
    background-color: #EEF4FF;
    border-color: #EEF4FF;
}

.editprojectdetail-item div:nth-child(2){
    width: 100%;
}

.editprojectdetail-item-desc{
    width: 85%;
    font-size: small;
    border: none;
    outline: none;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-left: 2px solid inherit;
    background-color: inherit;
}

.editprojectdetail-item-desc:hover{
    background-color: #EEF4FF;
}

.editprojectdetail-item-desc:focus{
    background-color: #EEF4FF;
    border-left: 2px solid #006AFF;
}


.editprojectdetail-item-save{
    font-size: small;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 1px;
    line-height: 16px;
    outline: none;
    background-color: #006AFF;
    color: white;
    border-radius: 5px;
    border: 1px solid #006AFF;
  
    transition-duration: 0.3s;
}
    
.editprojectdetail-item-save:hover{
    background-color: rgb(13, 69, 153);
    border-color: rgb(13, 69, 153);
    cursor: pointer;
}

.projectsubsection-heading-container{
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 4px;

    /*slight box shadow bottom*/
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    background-color: #EEF4FF;
}

.projectsubsection-heading-container:hover{
    cursor: pointer;

    /*box shadow all around on hover*/
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.projectsubsection-heading-container-noeffects{
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}


.projectsubsection-delete{
    background-color: #e56b6f;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    margin-left: 2%;
}

.projectsubsection-delete:hover{
    border-color: red;
    cursor: pointer;
}

.projectsubsection-delete:active{
    background-color: red;
}

.editprojectdetail-item-deletebutton{
    outline: none;
    border: none;
    background-color: white;
    color: #e56b6f;
    font-weight: bolder;
    padding: 0;
    margin-left: 5px;
}

.editprojectdetail-item-deletebutton:hover{ 
    cursor: pointer;
    text-shadow: 0 0 5px red;
}

.projectsubsection-additem{
    margin-top: -0.5%;
    margin-bottom: 2%;
}

.projectsubsection-additem-button{
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    background-color: #CDD9ED;
}

.projectsubsection-additem-button:hover{
    background-color: #98c1d9;
    cursor: pointer;
}

.editprojectdetail-subsection-choice{
    border: none;
    background-color: inherit;
    border-radius: inherit;

    min-width: 250px;
    line-height: 20px;
}

.editprojectdetail-subsection-verb{
    border: none;
    background-color: inherit;
    border-radius: inherit;

    min-width: 120px;
    max-width: 120px;
    line-height: 20px;
}

.projectsubsection-delete-button{
    height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 15px;

    float: right;
    margin-left: auto;
    margin-right: 1%;

    background-color: inherit;
}

.editprojectdetail-updown-container{
    display: grid;
}

.editprojectdetail-itemup-button{
    height: 8px;
    font-size: 8px;
    line-height: 8px;
    width: 16px;
    align-items: center;
    text-align: center;
    padding: 0px;

    border: none;

    background-color: inherit;

    border-radius: 5px;
}

.editprojectdetail-itemup-button:hover {
    font-weight: bold;
    cursor: pointer;
    background-color: white;
}

.editprojectdetail-itemdown-button{
    height: 8px;
    font-size: 8px;
    line-height: 8px;
    width: 16px;
    align-items: center;
    text-align: center;
    padding: 0px;

    border-radius: 5px;

    border: none;
    background-color: inherit;
}

.editprojectdetail-itemdown-button:hover {
    font-weight: bold;
    cursor: pointer;
    background-color: white;
}

.subsection-details-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2%;

    padding: 2%;
    padding-bottom: 0px;
}

.editprojectdetail-subsection-quanity-container{
    padding-bottom: 20px;
}

.editprojectdetail-subsection-quanity-header{
    height: 24px;
    gap: 5%;
    margin-bottom: 0.5%;

    display: flex;
    flex-direction: row;
}

.editprojectdetail-subsection-quanity{
    height: 32px;

    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
    
    padding-left: 8px;
    padding-right: 8px;
    
    background-color: #F6F6FA;
    box-shadow: inset 0 0 1px gray;
}

.editprojectdetail-subsection-quanity:hover{
    background-color: #EEF4FF;
}

.editprojectdetail-subsection-quanity:focus{
    height: 30px;
    outline: 1px solid #006AFF;
    background-color: #EEF4FF;
}

.editprojectdetail-subsection-materials-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2%;
    padding-bottom: 1%;

    padding: 2%;
}

.editprojectdetail-item-delete{
    background-color: inherit;
    font-weight: normal;
}

.editprojectdetail-item-delete:hover{
    font-weight: bold;
}