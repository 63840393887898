.questionlist-container{
    padding-top: 5%;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 15%;
}

.questionlist-createquestion-input{
    border-radius: 5px;
    outline: none;
    width: 80%;
    border: 1px solid lightgray;

    background-color: #F6F6FA;

    padding-left: 10px;
    padding-right: 10px;
}

.questionlist-createquestion-input:hover{
    background-color: #EEF4FF;
}

.questionlist-createquestion-input:focus {
    border-color: #006AFF;
    background-color: #EEF4FF;
}

.question-detail-item{
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.question-bids{
    margin-top: 5%;
}

.question-bids-title{
    font-size: 20px;
    font-weight: bold;
}

.question-bid{
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 1px solid gray;

    background-color: white;
}