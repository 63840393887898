.regsiterchoice-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;
    padding-bottom: 5%;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.regsiterchoice-background{
    height: 100vh;
    background-image: url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/about-background4.png');
    background-position: top;
    background-size: cover;
}

@media only screen and (max-width: 500px) {
    .regsiterchoice-container{
        padding-top: 20%;
    }
}