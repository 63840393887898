.linkmenu-menucontainer{
    position: absolute;
    top: 110%;
    width: 18vw;
    left: 0;
    max-height: 65vh;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    z-index: 1000;
    box-shadow: rgba(45, 32, 32, 0.3) 0px 2px 4px 0px;
}

.linkmenu-sections-container{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.linkmenu-trade-header{
    border-radius: 5px;
    background-color: whitesmoke;
    padding: 2%;

    display: grid;
    grid-template-columns: 1fr 15fr;
    align-items: center;
}

.linkmenu-trade-header:hover{
    cursor: pointer;
}

.linkmenu-trades-container{
    max-height: 55vh;

    margin-top: 2%;

    padding: 2%;

    display: flex;
    flex-direction: column;
    gap: 5px;

    overflow: scroll;
}

.linkmenu-section-title{
    color: #006AFF;
}

.linkmenu-trade-title{
    font-weight: bold;
}

.linkmenu-trade-container{
    border-radius: 5px;
    border: 1px solid lightgray;
}

.dropdownmenu-menu-item-title-measured{
    color: gray;
}