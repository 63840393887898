.editprojectdetail-section{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    /*border-top: 3px solid #006AFF;*/
    background-color: white;
}

.contractorsow-section{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    background-color: white;
    margin-bottom: 1%;
}

.editprojectdetail-collapseall{
    text-align: right;
    display: flex;
    gap: 10px;
    justify-content: right;
    margin-top: 4%;
}

.editprojectdetail-section-header {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
    height: 24px;
}

.editprojectdetail-section-choice {
    border: none;
    background-color: inherit;
    border-radius: inherit;

    min-width: 200px;
}

.editprojectdetail-section-choice-container{
    display: flex;
    align-items: center;
    gap: 10px;

    padding: 2%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border-radius: 4px;

    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.editprojectdetail-section-choice-container:hover{
    cursor: pointer;

    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.editprojectdetail-section-desc{
    margin-top: 5px;
    width: 100%;
    flex:1;
    padding: 10px;
    
    border: none;
    border-left: 2px solid white;
    resize: none;
    resize: vertical;

    background-color: #F6F6FA;
    box-shadow: inset 0 0 3px gray;

    border-radius: 5px;
}

.editprojectdetail-section-desc:hover{
    background-color: #EEF4FF;
}

.editprojectdetail-section-desc:focus{
    background-color: #EEF4FF;
    border: none;
    outline: none;
    border-left: 2px solid #006AFF;
}

.editprojectdetail-subsections-title{
    font-size: 20px;
    font-weight: bold;
}

.editprojectdetail-addsubsection-button {
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;   
}

.editprojectdetail-deletesection-button{
    float: right;
    margin-left: auto;
}

.editprojectdetail-addedconditionalsow-deletebutton{
    height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 15px;

    margin-right: 0%;
    margin-left: auto;
}

.editprojectdetail-addedconditionalsow-location-header{
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 10px;
}

.editprojectdetail-addedconditionalsow-locationchoice{
    color: #006AFF;
}

.editprojectdetail-addedconditionalsow-locationchoice:hover{
    text-decoration: underline;
}

.editprojectdetail-setion-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
    margin-bottom: 4px;
    margin-top: 2%;
    height: 30px;
}

.editprojectdetail-section-desc-save{
    padding-top: 2px;
    padding-bottom: 2px;
}

.editprojectdetail-addedconditionalsows{
    margin-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
}

.editprojectdetail-addedconditionalsow{
    margin-top: 1%;
    margin-bottom: 1%;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 3%;
    padding-right: 3%;

    border: 1px solid lightgray;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.editprojectdetail-addedconditionalsow:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.editprojectdetail-addedconditionalsow-header{
    display: flex;
    flex-direction: row;
    align-items: center;

    align-items: center;
    margin-bottom: 6px;
    margin-top: 6px;
}

.editprojectdetail-addedconditionalsow-header:hover{
    cursor: pointer;
}

.editprojectdetail-addedconditionalsows-title{
    font-size: 20px;
    font-weight: bold;
}

.editprojectdetail-conditionalsowrec{
    margin-top: 2%;
    padding-left: 3%;
    padding-right: 5%;
}

.editprojectdetail-conditionalsowrec:hover{
    font-weight: bold;
    cursor: pointer;
}

.editprojectdetail-conditionalsow-severities{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.editprojectdetail-conditionalsow-items{
    font-size: small;
    line-height: 0.95;
    margin-top: 10px;
}

.editprojectdetail-conditionalsow-items-container{
    margin-top: 10px;
    padding-bottom: 10%;
}

.editprojectdetail-addedconditionalsow-location{
    color: #006AFF;
}

.editprojectdetail-addedconditionalsow-location:hover{
    text-decoration: underline;
    cursor: pointer;
}

.editprojectdetail-addedconditionalsow-locations{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.editprojectdetail-addedconditionalsow-cond{
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 3%;
}

.editprojectdetail-addedconditionalsow-showdetails{
    color: gray;
}

.editprojectdetail-addedconditionalsow-showdetails:hover{
    cursor: pointer;
    color: #006AFF;
}

.editprojectdetail-conditionalsow-edit{
    color: #006AFF;
}

.editprojectdetail-conditionalsow-edit:hover{
    cursor: pointer;
    text-decoration: underline;
}

.editprojectdetail-conditionalsow-header{
    display: flex;
    gap: 10px;
}

.editprojectdetail-addedconditionalsow-toggleshow{
    color: #006AFF;
}

.editprojectdetail-addedconditionalsow-toggleshow:hover{
    cursor: pointer;
    text-decoration: underline;
}

.editprojectdetail-subsections-note{
    margin-left: 5%;
    color: gray;
}

.editprojectdetail-addedconditionalsows-note{
    color: gray;
}

.editprojectdetail-addedconditionalsow-relevance{
}

.editprojectdetail-addedconditionalsow-locations{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.editprojectdetail-addedconditionalsows-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.editprojectdetail-subsections-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 4%;
}

.editprojectdetail-section-other{
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
  
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    background-color: #F6F6FA;
    box-shadow: inset 0 0 1px gray;
}

.editprojectdetail-section-other:hover{
    background-color: #EEF4FF;
}

.editprojectdetail-section-other:focus{
    outline: 1px solid #006AFF;
    background-color: #EEF4FF;
}

.editproject-sowgeneration-spinner{
    width: 50px;
    height: 50px;
    border-width: 10px;
}

.editproject-sowgeneration-spinner-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.editprojectdetail-subsection-other{
    width: 100%;

    height: 32px;

    background-color: white;
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204);
  
    padding-left: 8px;
    padding-right: 8px;

    background-color: #F6F6FA;
}

.editprojectdetail-subsection-other:hover{
    background-color: #EEF4FF;
}

.editprojectdetail-subsection-other:focus{
    height: 30px;
    outline: 1px solid #006AFF;
    background-color: #EEF4FF;
}

.editprojectdetail-addedconditionalsows-addall{
    padding-left: 10px;
    padding-right: 10px;
}

.editprojectdetail-conditionalrecs-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3%;
}

.editprojectdetail-conditionalrecs-locations{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.editprojectdetail-sectionup-button{
    height: 12px;
    font-size: 12px;
    line-height: 12px;
    width: 20px;
    align-items: center;
    text-align: center;
    padding: 0px;

    border: none;

    background-color: inherit;

    border-radius: 5px;
}

.editprojectdetail-sectionup-button:hover {
    font-weight: bold;
    cursor: pointer;
    background-color: white;
}

.editprojectdetail-sectiondown-button{
    height: 12px;
    font-size: 12px;
    line-height: 12px;
    width: 20px;
    align-items: center;
    text-align: center;
    padding: 0px;

    border: none;

    background-color: white;

    border-radius: 5px;
}

.editprojectdetail-sectiondown-button:hover {
    font-weight: bold;
    cursor: pointer;
    background-color: whitesmoke;
}

.editprojectdetail-section-body-container{
    padding: 2%;
}

.editprojectdetail-sectionup-container{
    display: block;
    position: relative;
    padding: 0px;
    margin: 0px;
    line-height: 0px;
}

.editprojectdetail-updown-hint{
    position: absolute;
    bottom: 20px;
    left: -50px;
    width: 100px;
    height: 30px;

    padding: 2%;

    padding-left: 10%;
    padding-right: 10%;
    
    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    white-space: nowrap;

    background-color: lightgray;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 15%) 2px 2px 2px 2px;
}
