.contractoropenhouse-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.contractoropenhouse-title{
    font-size: 20px;
    font-weight: bold;
}

.contractoropenhouse-backbutton{
    margin-left: 15px;
}

.contractoropenhouse-desc{
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
}

.contractoropenhouse-howitworks{
    margin-top: 15px;
}

.contractoropenhouse-signedup{
    margin: auto;

    background-color: #CDD9ED;
    border-radius: 5px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    display: flex;
    flex-direction: row;
    gap: 10px;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
}

.contractoropenhouse-cancelbutton{
    width: fit-content;
}

.contractoropenhouse-section{
    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    background-color: white;

    margin-top: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractoropenhouse-section-taken{
    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    background-color: #CDD9ED;

    margin-top: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractoropenhouse-section-title{
    font-weight: bold;
}

.contractoropenhouse-slot-times{
    width: 175px;
    margin-top: 2px;
}

.contractoropenhouse-slot{
    padding: 5px;
    padding-left: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
}

.contractoropenhouse-slot-selectbutton{
    padding-top: 2px;
    padding-bottom: 2px;
}

.contractoropenhouse-slot-free{
    background-color: #1CAC78;
}

.contractoropenhouse-slot-taken{
    background-color: #CDD9ED;
}

.contractoropenhouse-slot-confirm{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.contractoropenhouse-confirm-buttons{
    height: 30px;
    padding: 6px;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.contractoropenhouse-signedup-date div:first-child{
    background-color: #CDD9ED;
    border: none;
    font-weight: bold;
    color: black;
}

.contractoropenhouse-signedup-date *{
    color: black;
}

.contractoropenhouse-slot-date-published div:first-child{
    background-color: white;
    border: none;
    font-weight: bold;
    color: black;
}

.contractoropenhouse-slot-date-published *{
    color: black;
}