.contractordashboard-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 10%;
}

.contractordashboard-addedprojects-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.contractordashboard-searchproject{
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    color: black;

    margin-top: 2%;

    overflow: hidden;
}

.contractordashboard-searchproject:hover{
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
    cursor: pointer;
}

.contractordashboard-addedproject{
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    color: black;

    margin-top: 2%;
}

.contractordashboard-addedproject:hover{
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
    cursor: pointer;
}

.contractordashboard-link{
    text-decoration: none;
}

.contractordashboard-addedprojects-title{
    font-weight: bold;
    font-size: 20px;
    margin-top: 5%;
}

.contractordashboard-search-projects{
    margin-top: 10%;
}

.contractordashboard-addedprojects-desc{
    width: 200px;
    color: gray;
}

.contractordashboard-addedproject-desc{
    margin: 10px;
    padding-left: 5px;
    border-left: 2px solid #006AFF;
    padding-top: 5px;
    padding-bottom: 5px;
}

.contractordashboard-addedproject-blue{
    color: #006AFF;
}

.contractordashboard-addedproject-green{
    color: #74c005;
}

.contractordashboard-addedproject-gray{
    color: gray;
}

.contractordashboard-createdprojects-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractordashboard-createdprojects-title{
    font-weight: bold;
    font-size: 20px;
}

.contractordashboard-title{
    font-size: 36px;
    font-weight: bold;
}

.contractordashboard-createdprojects-upgrade{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 10px;

    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: white;
    padding: 2%;
}

.contractordashboard-createdprojects-upgrade-desc{
    max-width: 70%;
}

.contractordashboard-createdprojects-upgrade-container{
    display: flex;
    flex-direction: column;

    gap: 10px;

    margin-bottom: 5%;
}