.dashboard-container{
    height: fit-content;
    padding-top: 5%;
    padding-bottom: 10%;
    padding-left: 20%;
    padding-right: 20%;
}

.dashboard-title{
    font-size: 36px;
    font-weight: bold;
}

.dashboard-projects{
    margin-top: 2%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.dashboard-project{
    padding: 2%;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    background-color: white;
    border-top: 3px solid #006AFF;
    min-height: 100px;

    transition-duration: 0.3s;
}

.dashboard-project:hover{
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
}

.dashboard-project-editbutton{
    float: right;
    margin-right: 1%;
}

.dashboard-project-editbutton:hover{
    background-color: #98c1d9;
    cursor: pointer;
}

.dashboard-project-address{
    font-weight: bold;
}

.dashboard-project-desc{
    margin-top: 2%;
    min-height: 50px;
}

.dashboard-createproject-closebutton{
    margin-left: 1%;
}

.dashboard-createproject-buttoncontainer{
    margin-top: 10px;
}

.dashboard-createproject-modal{
    padding: 2%;
}

.dashboard-createproject-address {
    display: flex;
    flex-direction: row;

    border-radius: 5px;

    height: 30px;
    margin-top: 10px;
}

.dashboard-createproject-details{
    width: 120px;
    text-align: center;
    vertical-align: middle;
    padding-top: 2px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #006AFF;
    color: white;
}

.dashboard-createproject-modal-title{
    font-weight: bold;
    font-size: 20px;
}

.dashboard-createproject-input {
    width: 100%;
    border-width: 1px;
    padding-left: 10px;
    border-style: hidden;
    border: 1px solid lightgray;
    background-color: #F6F6FA;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dashboard-createproject-input:hover {
    background-color: #EEF4FF;
}

.dashboard-createproject-input:focus {
    border: 1px solid #006AFF;
    background-color: #EEF4FF;
    outline: none;
}

.dashboard-createbutton{
    margin-left: 20px;
    margin-top: 1%;
}

.dashboard-project-actionlink{
    text-decoration: none;
    color: black;
}

.dashboard-project-actionlink:hover{
    color: black;
}

.dashboard-project-action-container{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.dashboard-projecttimeline-container{
    padding: 2%;
}

.dashboard-projecttimeline-items{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.dashboard-projecttimeline-step-title{
    font-weight: bold;
    text-align: center;
}

.dashboard-contractorchoice{
    padding: 2%;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-bottom: 10px;
}

.dashboard-contractorchoice:hover{
    background-color: aliceblue;
    cursor: pointer;
}

.dashboard-contractorchoice-on{
    border-color: #006AFF;
    background-color: aliceblue;
}

.dashboard-contractorchoice-on:hover{
    background-color: aliceblue;
}

.dashboard-projecttimeline-stepcontainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.dashboard-projecttimeline-progressbar-container{
    position: relative;
    width: 100%;
    height: fit-content !important;
    border-radius: 10px;
}

.dashboard-projecttimeline-progressbar-background{
    background-color: lightgray;

    position: absolute;
    top: 5px;
    left: 17%;
    width: 67%;
    height: 10px !important;
}

.dashboard-projecttimeline-progressbar{
    position: absolute;
    top: 5px;
    left: 17%;
    width: 0%;
    height: 10px !important;
    background: #97C2FF;
}


.dashboard-projecttimeline-progress-milestones{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.dashboard-projecttimeline-progress-milestone{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    width: 30%;
}

.dashboard-projecttimeline-progress-dot{
    height: 20px;
    width: 20px;
    border-radius: 25px;
    background: lightgray;
    border: 2px solid white;
    z-index: 1;
}

.dashboard-projecttimeline-progress-dot-done{
    height: 20px;
    width: 20px;
    border-radius: 25px;
    background: #006AFF;
    border: 2px solid white;
    z-index: 1;
}

.dashboard-projecttimeline-progress-title{
    font-weight: bold;
    text-align: center;
    margin-bottom: 2%;
}

.dashboard-address-autocomplete-dropdown-container{
    padding: 10px;
}

.dashboard-address-suggestion-item-active:hover{
    font-weight: bold;
    cursor: pointer;
}

.dashboard-project-link{
    color: black;
    text-decoration: none;
}

.dashboard-project-link:hover{
    color: black;
}

.dashboard-project-status-gray{
    color: gray;
}

.dashboard-project-status-green{
    color: #74c005;
}

.dashboard-project-status-blue{
    color: #006AFF;
}

.dashboard-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.projectdetail-projecttimeline-ministeps-active{
    box-shadow: #006AFF 0px 0px 4px 0px !important;
}