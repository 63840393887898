.pricing-container{
    display: grid;
    grid-template-columns: 1fr 1fr;

    padding-left: 5%;
    padding-right: 5%;

    gap: 20px;

    overflow: hidden;
    min-height: 500px;
}

.contractorcheckout-header{
    font-size: 30px;
    font-weight: bold;
    
    margin-bottom: 2%;
}
.contractorcheckout-subheader{
    font-size: 20px;
    color: #7a7a7a;
}

.pricing-plans-container{
    gap: 20px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pricing-details-container{
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    
    padding: 2%;
    position: relative;

    padding-top: 5%;
}

.pricing-plan-details {
    list-style-type: none; 
}

.pricing-plan-details > li {
    text-align: left;
}

.checkmark{
    color: green;
    margin-right: 10px;
    font-size: 20px;
}

.pricing-plan-container{
    padding: 5%;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    background-color: white;
}

.contractorcheckout-container{
    background-color: white;
    padding: 2%;

    padding-top: 3%;
    padding-bottom: 5%;

    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.pricing-plan-price{
    font-size: 50px;
    font-weight: 500;
    color: #0066FF;
}

.pricing-plan-discount-container{
    position: absolute;
    top: 5px;
    right: 10px;
    transform: rotate(30deg);
}

.pricing-plan-discount{
    font-size: 20px;
    font-weight: 800;
}

.pricing-plan-price-container{
    margin-top: 2%;
    margin-bottom: 2%;
}

.pricing-plan-details-container{    
    padding: 2%;

    border-radius: 5px;

    margin-top: 3%; 
}

.pricing-plan-details-header{
    color: #006aff;
    font-weight: bold;
}

.pricing-loading-container{
    text-align: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 75%;
    margin: 3rem auto;
}

.pricing-plan-header {
    color: #212529;
    font-size: 24px;
    line-height: 1.25;

    font-weight: bold;
}

.tab-slider--nav {
    width: 100%;
    float: left;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
}

.tab-slider--tabs {
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    background: #A7CEFC;
    border-radius: 35px;
    height: 35px;
    user-select: none;
    white-space: nowrap;
}

.tab-slider--tabs:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: #006aff; 
    border: 2px solid #212529;
    border-radius: 35px;
    transition: all 250ms ease-in-out;
}

.tab-slider--tabs.slide:after {
    left: 50%;
}

.tab-slider--trigger {
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
    color: #212529;
    text-align: center;
    padding: 11px 20px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    display: inline-block;
    transition: color 250ms ease-in-out;
    user-select: none;
}

.tab-slider--trigger.active {
    color: #ffffff;
}

@media (max-width: 1484px) {
    .tab-slider--trigger {
      font-size: 10px;
    }
  }

.tab-slider--body {
    display: none;
}

.tab-slider--body.active {
    display: block;
    margin-bottom: 20px;
}

.pricing-plan-discount {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 10px;
    background-color: rgba(255, 228, 146, 1);
    color: #212529; 
    border: 0px solid #FFE492; 
    font-weight: bold; 
  }

  .pricing-plan-savings {
    color: #0066FF;
    font-weight: bold;
  }

  .contractorcheckout-background{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 7%;
    padding-bottom: 7%;

    text-align: center;

    background-image: url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/about-background4.png');
    background-position: top;
    background-size: cover;
}

@media only screen and (max-width: 500px) {
    .contractorcheckout-background{
        padding-left: 0%;
        padding-right: 0%;
    }    

    .pricing-container{
        display: flex;
        flex-direction: column;
    }
}