.notfoundpage-container{
    height: 100vh;
    width: 100vw;
    background-color: lightblue;

    text-align: center;
    padding: 20%;
    justify-content: middle;   
    font-weight: bold;
    font-size: 20px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    overflow-y: hidden;
}