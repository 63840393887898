.contractorprojectchangeorders-versions-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    margin-top: 2%;
}

.contractorprojectchangeorders-version-link{
    text-decoration: none;
    color: black
}

.contractorprojectchangeorders-version-link{
    text-decoration: none;
    color: black
}

.contractorprojectchangeorders-version-container{
    padding: 2%;
    padding-left: 4%;
    padding-right: 4%;
    border-radius: 5px;
    border: 1px solid #C4C4C4;

    background-color: white;
}

.contractorprojectchangeorders-version-date{
    font-size: 12px;
    font-weight: 500;
    color: #4F4F4F;

    text-decoration: none;
}

.contractorprojectchangeorders-version-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractorprojectchangeorders-current-change-order-header-container{
    padding: 2%;

    background-color: white;
    border-radius: 5px;
    margin-top: 2%;

    border: 1px solid lightgray;

    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contractorprojectchangeorders-current-change-order-header{
    font-size: 20px;
    font-weight: 500;
}

.contractorprojectchangeorders-current-change-order-step{
    border: 1px solid lightgray;
    border-radius: 5px;

    padding: 2%;
}

.contractorprojectchangeorders-current-change-order-step-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    margin-top: 2%;
}

.contractorprojectchangeorders-pastversions-title{
    font-size: 20px;
    font-weight: 500;
    color: #4F4F4F;

    margin-top: 5%;
}