.demorequest-container {
    height: 100vh;
    width: 100vw;

    padding-left: 10%;
    padding-right: 10%;

    padding-top: 80px;

    overflow: hidden;
}

.demorequest-header {
    margin-top: 5vh;
}

.demorequest-header-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
}

.demorequest-header-desc {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

.demorequest-body {
    margin-top: 5vh;
}

.demorequest-body-section {
    margin-top: 5vh;
}

.demorequest-body-section-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.demorequest-body-input-text {
    margin-top: 2vh;
    width: 100%;
    height: 48px;

    border: 1px solid #E0E0E0;
    border-radius: 4px;

    padding-left: 16px;
    padding-right: 16px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.demorequest-body-input-file {
    background: rgba(0, 106, 255, 0.25);
}

.demorequest-upload-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: 150px;
    width: 500px;

    border: 2px dashed #EEEEEE;
    background-color: white;
    border-radius: 5px;
    outline: none;

    padding: 5% 5%;
}

.demorequest-upload-placeholder:hover {
    cursor: pointer;
    background-color: #f9f9fb;
    color: #006AFF;

    border: 2px dashed #006AFF;
}

.demorequest-upload-placeholder-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.demorequest-message-input{
    width: 500px;
    height: 50px;
    padding: 10px;
    padding-left: 20px;
    border: none;
    border-radius: 5px;

    background: white;

    border: 1px solid #EEEEEE;

    outline: none;
}

.demorequest-message-input:hover {
    border: 1px solid #006AFF;
}

.demorequest-message-input:focus {
    border: 1px solid #006AFF;

    background-color: aliceblue;
}

.demorequest-message-input::placeholder {
    color: rgba(0, 106, 255, 0.65);
}

.demorequest-message-input-container{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.demorequest-body{
    gap: 5%;
}


.demorequest-request-button-container{
    margin-top: 5vh;
}

.demorequest-body-file{
    margin-bottom: 5px;
}

.demorequest-request-button{
    width: 200px;
    height: 50px;

    border-radius: 5px;
    background-color: #006AFF;
    color: white;
    border: 1px solid #006AFF;

    font-size: 18px;
    font-weight: 500;

    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.demorequest-request-button:hover{
    cursor: pointer;
    background-color: rgb(13, 69, 153);
    border-color: rgb(13, 69, 153);
}

.demorequest-request-button:active{
    background-color: #0050B2;
    border-color: #0050B2;
}

.demorequest-request-button-disabled{
    width: 200px;
    height: 50px;

    border-radius: 5px;
    background-color: #EEEEEE;
    color: gray;
    border: 1px solid #EEEEEE;

    font-size: 18px;
    font-weight: 500;

    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

.demorequest-request-button-disabled:hover{
    cursor: not-allowed;

    background-color: #EEEEEE;
    border-color: #EEEEEE;
}

.demorequest-request-sucess{
    margin-top: 5vh;
    color: #006AFF;

    font-size: 24px;
    font-weight: 700;
}

.demorequest-request-sending{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    gap: 10px;
}

.demorequest-request-sending-text{
    font-size: 20px;
    font-weight: 500;
}