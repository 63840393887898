.contractorsign-sigcanvas{
    border: 2px solid black;
}

.contractorsign-container{
    display: grid;
    grid-template-columns: 7fr 3fr;
    height: 100vh;
    padding-top: 6vh;
    overflow-y: hidden;
}

.contractorproject-contractorsign-container{
    padding-left: 17%;

    display: grid;
    grid-template-columns: 3fr 7fr;
    height: 100vh;
    padding-top: 6vh;
    overflow-y: hidden;
}

.contractorsign-pdf{
    height: 94vh;
    width: fill;
}

.contractorsign-right{
    margin-top: 6%;
}

.contractorsign-title{
    padding-left: 6%;
}

.contractorsign-input{
    border-radius: 5px;
    padding: 2%;
    outline: none;
    border: 1px solid lightgray;
    width: 60%;
}

.contractorsign-input:focus{
    border-color: #006AFF;
}

.contractorsign-signature-input{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin-top: 5%;
}

.contractorsign-left{
    background-color: gainsboro;
}

.contractorsign-submit-button{
    padding-left: 6%;
    margin-top: 5%;
}

.contractorsign-right-backbutton{
    margin-left: 6%;
    margin-bottom: 10%;    
    padding-right: 7%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}