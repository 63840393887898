.templatelist-section-container{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    border-top: 3px solid #006AFF;
    background-color: white;
    margin-bottom: 2%;
}

.templatedetail-subsection-container{
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding: 2%;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.templatedetail-subsection-desc{
    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.templatedetail-subsection-container:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.templatedetail-items-container{
    font-size: small;
    padding-left: 2%;
    padding-bottom: 2%;
}

.templatedetail-subsection-header{
    padding-top: 1%;
    padding-bottom: 1%;
}

.templatedetail-subsection-header:hover{
    cursor: pointer;
}

.templatedetail-conditionals-container{
    padding-left: 5%;
    padding-right: 5%;
}

.templatedetail-addedconditionalsow{
    margin-top: 1%;
    padding-bottom: 0px;
    padding-left: 3%;
    padding-right: 3%;

    border: 1px solid lightgray;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.templatedetail-addedconditionalsow:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.templatedetail-addedconditionalsow-header:hover{
    cursor: pointer;
}

.templatedetail-addedconditionalsow-header{
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.templatedetail-addedconditionalsow-locations{
    display: flex;
    gap: 10px;
    margin-top: -0.5%;
    padding-top: 0%;
}

.templatedetail-subsection-title{
}

.templatelist-section-title{
    font-weight: bold;
    font-size: 20px;
}

.templatelist-section-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1%;
    padding: 2%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    border-radius: 4px;

    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.templatelist-section-header:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.templatedetail-options-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.templatedetail-subsection-details-container{
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    align-items: center;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.templatedetail-subsections-container{
    padding-bottom: 2%;
}