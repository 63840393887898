.generatecontract-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.generatecontract-progress{
    position: relative;
    width: 100%;
    height: fit-content !important;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;

    margin-bottom: 5%;
    margin-top: 5%;
}

.generatecontract-progress-backgroundbar{
    background-color: lightgray;

    position: absolute;
    top: 5px;
    left: 10%;
    width: 80%;
    height: 10px !important;
}

.generatecontract-progress-bar {
    position: absolute;
    top: 5px;
    left: 10%;
    width: 0%;
    height: 10px !important;
    background: lightblue;
    transition: width ease-in 250ms;
}

.generatecontract-progress-milestone{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 20%;
}

.generatecontract-progress-milestone:hover{
    cursor: pointer;
    font-weight: bold;
}

.generatecontract-progress-milestone:hover .generatecontract-progress-milestone-dot{
    background: #006AFF;
}

.generatecontract-progress-milestone-title{
    text-align: center;
    position: relative;
    top: 5px;
}

.generatecontract-progress-milestone-dot{
    height: 20px;
    width: 20px;
    border-radius: 25px;
    background: lightcyan;
    border: 2px solid white;
    cursor: pointer;
    z-index: 1;
}

.generatecontract-progress-milestone-dot{
    background: lightskyblue;
}

.generatecontract-progress-milestone-active .generatecontract-progress-milestone-dot{
    background: #006AFF;
}

.generatecontract-progress-milestone-active .generatecontract-progress-milestone-title{
    font-weight: bold;
}

.generatecontract-currentform-container{
    padding-left: 20%;
    padding-right: 20%;
}

.generatecontract-substep-title{
    font-weight: bold;
    margin-bottom: 10px;
}

.generatecontract-input-container {
    display: flex;
    flex-direction: row;

    border-radius: 5px;

    height: 30px;

    margin-bottom: 2%;
}

.generatecontract-input-container-boolean{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    border-radius: 5px;

    height: 30px;

    margin-bottom: 2%;
}

.generatecontract-input-details{
    text-align: center;
    vertical-align: middle;
    
    padding-top: 2px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #006AFF;
    color: white;

    padding-left: 20px;
    padding-right: 20px;
}

.generatecontract-input-details-min{
    text-align: center;
    vertical-align: middle;
    
    padding-top: 2px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #006AFF;
    color: white;

    min-width: 150px;

    padding-left: 20px;
    padding-right: 20px;
}

.generatecontract-input-details-boolean{
    text-align: center;
    vertical-align: middle;
    
    padding-top: 2px;
    border-radius: 5px;
    background-color: #006AFF;
    color: white;

    min-width: 150px;

    padding-left: 20px;
    padding-right: 20px;
}

.generatecontract-input {
    width: 100%;
    border-width: 1px;
    padding-left: 10px;
    border-style: hidden;
    border: 1px solid lightgray;
    background-color: #F6F6FA;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.generatecontract-field-input{
    height: 30px;
    width: 100%;
    border-width: 1px;
    padding-left: 10px;
    border-style: hidden;
    border: 1px solid lightgray;
    background-color: #F6F6FA;

    border-radius: 5px;
}

.generatecontract-field-input:hover{
    background-color: #EEF4FF;
}

.generatecontract-field-input:focus{
    outline: none;
    background-color: #EEF4FF;
    border: 1px solid #006AFF;
}

.generatecontract-input-choices{
    height: 30px;
    width: 100%;
    border-width: 1px;
    padding-left: 10px;
    border-style: hidden;
    border: 1px solid lightgray;
    background-color: #F6F6FA;

    border-radius: 5px;
}

.generatecontract-input-choices:hover{
    cursor: pointer;
}

.generatecontract-input:hover {
    background-color: #EEF4FF;
}

.generatecontract-input:focus {
    border: 1px solid #006AFF;
    background-color: #EEF4FF;
    outline: none;
}

.generatecontract-substep-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 3%;
}

.generatecontract-listview-container{
    padding-top: 1%;
    padding-left: 5%;
    padding-right: 5%;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.generatecontract-list-container{
    border-radius: 5px;
    border: 1px solid lightgray;
}

.generatecontract-list-header{
    border-bottom: 1px solid lightgray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    background-color: #EEF4FF;
    padding: 2%;

    display: grid;
    grid-template-columns: 8fr 1fr;

    gap: 2%;
}

.generatecontract-list-header:hover{
    cursor: pointer;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
}

.generatecontract-list-items{
    padding: 2%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    background-color: white;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.generatecontract-list-item{
    display: grid;
    grid-template-columns: 2fr 3fr;
}

.generatecontract-list-item-title{
    font-weight: bold;
    width: fit-content;
}

.generatecontract-header{
    display: grid;
    grid-template-columns: 5fr 2fr;
    align-items: center;
}

.generatecontract-viewchoice{
    padding: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 10px;
    gap: 2%;
}

.generatecontract-title{
    font-size: 36px;
    font-weight: bold;
    padding-right: 20%;
}

.viewcontract-modal .modal-content{
    border-radius: 0px;
    border: none;
    padding: 0px;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;

    position: fixed;
}

.viewcontract-file{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.viewcontract-header{
    padding: 5px;
    padding-right: 20px;
    gap: 10px;
}

.viewcontract-title{
    text-align: center;
    width: 100%;
    height: 40px;
    padding: 0px;
}

.generatecontract-previewcontract-button{
    margin-top: 10px;
}

.generatecontract-list-status-complete{
    color: #006AFF;
}

.generatecontract-input-datepicker{
    height: fill;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.generatecontract-input-datepicker div:first-child{
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.generatecontract-substep-done{
    align-items: center;
    text-align: center;

    font-weight: bold;
}

.generatecontract-substep-container-done{
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.generatecontract-listview-header{
    margin-right: 0px;
    margin-left: auto;
}

.generatecontract-substep-item-desc{
    line-height: 20px;
    padding-bottom: 0px;
    margin-bottom: 3px;

    padding-top: 10px;
}

.editwarrantymodal-title{
    font-weight: bold;
    margin-bottom: 2%;
}

.generatecontract-warrantyitem-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.generatecontract-warrantyitem-desc{
    background-color: #F6F6FA;
    border: none;
    outline: none;
    padding: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-left: 2px solid rgba(255, 0, 0, 0);
}

.generatecontract-warrantyitem-desc:hover{
    background-color: #EEF4FF;
}

.generatecontract-warrantyitem-desc:focus{
    background-color: #EEF4FF;
    border-left: 2px solid #006AFF;
}

.editwarrantymodal-warrantyitems-container{
    margin-bottom: 2%;
}

.generatecontract-list-warrantyitem{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.generatecontract-paymentitem-amount{
    width: 90px
}

.generatecontract-paymentitem-date{
    border: none;
    outline: none;
    height: 24px;
    background-color: #F6F6FA;
    border-radius: 0px;
    padding-left: 2px;
}

.generatecontract-paymentitem-date:hover{
    background-color: #EEF4FF;
}

.generatecontract-list-paymentitem{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    gap: 10px;
    font-size: small;
}

.generatecontract-paymentitem-desc{
    width: 64%;
    font-size: small;
    height: 24px;
}

.generatecontract-list-paymentitems .generatecontract-list-paymentitem:nth-child(even) {
    background-color: #F6F6FA;
}

.viewcontract-sent{
    margin-right: 1%;
    color: #006AFF;
}

.contractorproject-contract-headeroptions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.drawcontractpdf-message-container{
    border-radius: 5px;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    padding: 2%;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
}

.generatecontract-list-item-sum{
    text-align: right;
    padding-right: 20px;
}