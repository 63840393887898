.landing5-container {
    /*noto sans*/
    font-family: 'Noto Sans', sans-serif;

    overflow-x: hidden;
    overflow-y: scroll;

    padding-top: 80px;
}

.landing5-content {
    overflow: hidden;

    margin-top: 50px;
}

.landing5navbar-conatiner {
    height: 80px;
    width: 100vw;

    background-color: white;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0% 10%;

    position: fixed;
    top: 0;

    z-index: 4;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.landing5navbar-logo-image {
    height: 50px;
}

.landing5navbar-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.landing5-section1 {
    width: 100vw;
    padding: 0 10%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: white;
}

.landing5-section1-left {
    width: 40%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    padding: 10% 0%;

    z-index: 2;
}

.landing5-section1-right {
    padding-top: 5%;
    padding-bottom: 5%;

    width: 60%;

    display: flex;
    justify-content: right;
    align-items: center;

    z-index: 2;
}

.landing5-section1-video {
    width: 90%;

    border: 10px solid aliceblue;
    border-radius: 5px;
}

.landing5-section1-left-title {
    font-size: 50px;
    line-height: 60px;

    font-weight: 700;

    padding-right: 5%;
}

.landing5-section1-left-subtitle {
    font-size: 24px;
    line-height: 30px;

    padding-right: 5%;
}

.landing5-section1-left-button {
    padding-top: 40px;

    display: flex;
    gap: 20px;
}

.landing5-section1-container {
    position: relative;

    background-color: #043873;
}

.landing5-section1-background {
    position: absolute;
    top: -2%;
    left: 0%;
    height: 50%;
    width: 100%;

    z-index: 1;
}

.landing5-section4-background {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 50%;
    width: 200%;

    z-index: 1;
}

.landing5-section5 {
    width: 100vw;

    background-color: white;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 5% 0%;

    padding-top: 7%;
    padding-bottom: 7%;
}

.landing5-section5-left {
    padding-left: 10%;
}

.landing5-section5-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 700;

    padding-bottom: 20px;
}

.landing5-section5-subtitle {
    font-size: 20px;
    line-height: 25px;
}

.landing5-section5-right {
    padding-right: 10%;
}

.landing5-section5-right-title {
    font-size: 60px;
    line-height: 35px;

    font-weight: 900;

    padding-bottom: 20px;

    color: #006aff;
}

.landing5-section5-right-subtitle {
    font-size: 20px;
    line-height: 25px;
}

.landing5-section2 {
    width: 100vw;

    background-color: white;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 5% 0%;
}

.landing5-section2-left {
    width: 50%;
    height: 50%;

    padding-left: 5%;

    position: relative;
}

.landing5-section2-image {
    position: relative;

    width: 100%;

    border: 10px solid aliceblue;
    border-radius: 5px;

    z-index: 3;
}

.landing5-section3-image {
    width: 100%;

    border: 10px solid whitesmoke;
    border-radius: 5px;
}

.landing5-section2-left-image1 {
    position: absolute;
    top: 0%;
    left: -10%;
    height: 30vh;

    z-index: 1;

    border: 10px solid aliceblue;
    border-radius: 5px;
}

.landing5-section2-image1 {
    height: 40vh;
}

.landing5-section2-image2 {
    height: 40vh;
}

.landing5-section2-left-image2 {
    position: absolute;
    top: 20%;
    left: 10%;

    z-index: 2;

    border: 10px solid aliceblue;
    border-radius: 5px;
}

.landing5-section2-right {
    width: 50%;
    padding-right: 10%;
    padding-left: 5%;

    display: flex;
    flex-direction: column;
    gap: 40px;
}

.landing5-section2-image {
    width: 100%;
}

.landing5-section2-right-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 600;
}

.landing5-section2-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.landing5-section2-body-item {
    display: flex;
    align-items: center;
    gap: 20px;

    border: 1px solid #E5E5E5;
    border-radius: 5px;

    padding: 10px 20px;

    background-color: white;
}

.landing5-section2-body-item-icon {
    border: 1px solid #006aff;
    background-color: aliceblue;
    border-radius: 5px;
    padding: 5px;
}

.landing5-section2-body-item-content-title {
    font-size: 20px;
    line-height: 25px;

    font-weight: 700;
}

.landing5-section2-body-item-content-subtitle {
    font-size: 15px;
    line-height: 20px;

    color: #525252;
}

.landing5-section2-body-item-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.landing5-section-dark {
    background-color: #043873;
}

.landing5-section3-left {
    width: 50%;
    padding-left: 10%;
    padding-right: 5%;

    display: flex;
    flex-direction: column;
    gap: 40px;
}

.landing5-section3-right {
    width: 50%;

    padding-right: 5%;
}

.landing5-section2-right-title-dark {
    color: white;

    text-align: center;
}

.landing5-section-blue {
    background-color: aliceblue;
    border-top: 1px solid #006aff;
    border-bottom: 1px solid #006aff;
}

.landing5-section2-right-title-blue {
    color: #006aff;
}

.landing5-section4 {
    background-color: #043873;

    padding: 5% 10%;

    display: flex;
    flex-direction: column;
    gap: 40px;
}

.landing5-section4-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}

.landing5-section4-body-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    background-color: aliceblue;

    padding: 50px 40px;
    padding-top: 20px;
    border-radius: 5px;

    text-align: center;

    z-index: 3;
}

.landing5-section4-body-item-icon {
    border-radius: 5px;
    padding: 20px;
}

.landing5-section4-body-item-content-title {
    font-size: 30px;
    line-height: 35px;

    font-weight: 700;
}

.landing5-section4-body-item-content-subtitle {
    font-size: 20px;
    line-height: 25px;

    color: #525252;
}

.landing5-backedby-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #043873;
}

.landing5-footer-container {
    background-color: #043873;

    padding: 5% 10%;

    display: flex;
    flex-direction: column;
    gap: 50px;

    color: white;

    position: relative;
    z-index: 4;
}

.landing5-footer-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landing5-backedby-container {
    padding: 10% 10%;

    background-color: white;
}

.landing5-backedby-title {
    font-size: 30px;

    font-weight: 700;

    z-index: 3;
}

.landing5-footer-get-started {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.landing5-footer-body-mobile {
    display: none;
}

.landing5navbar-mobile-menu {
    position: fixed;
    top: 80px;

    height: calc(100vh - 80px);
    width: 100vw;

    padding: 10%;
    padding-top: 5%;

    display: flex;
    flex-direction: column;
    gap: 20px;

    z-index: 4;

    background-color: white;
}

.landing4-header-menu-login-button {
    background-color: #FFE492;
    outline: none;
    border: none;

    font-size: 18px;
    font-weight: 500;

    border-radius: 5px;

    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

.landing4-header-menu-login-button:hover {
    cursor: pointer;
    background-color: #FFD700;
}

.landing4-header-menu-button2 {
    background-color: transparent;
    color: white;

    outline: none;

    border: 2px solid #006aff;
    border-radius: 5px;

    font-size: 18px;
    font-weight: 500;

    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
}

.landing4-header-menu-button2:hover {
    cursor: pointer;
    background-color: rgb(13, 69, 153);
}


@media (max-width: 1200px) {
    .landing5-section1-left-title {
        font-size: 40px;
        line-height: 50px;
    }

    .landing5-section1-left-subtitle {
        font-size: 20px;
        line-height: 25px;
    }

    .landing5-section1-left {
        gap: 20px;
    }

    .landing5-section1-left-button {
        padding-top: 20px;
    }

    .landing5-section2-right-title {
        font-size: 25px;
        line-height: 30px;
    }

    .landing5-section2-body-item-content-title {
        font-size: 18px;
        line-height: 23px;
    }

    .landing5-section2-body-item-content-subtitle {
        font-size: 13px;
        line-height: 18px;
    }

    .landing4-header-menu-button {
        font-size: 16px;
    }

    .landing4-header-menu-button2 {
        font-size: 16px;
    }

    .landing5-section4-body-item-content-title {
        font-size: 25px;
        line-height: 30px;
    }

    .landing5-section4-body-item-content-subtitle {
        font-size: 18px;
        line-height: 23px;
    }

    .landing5-backedby-title {
        font-size: 25px;
    }

    .landing2-backedby-img {
        height: 50px;
    }

    .landing2-navbar-logo-img {
        height: 50px;
    }

    .landing2-footer-title {
        font-size: 25px;
        line-height: 30px;
    }

    .landing5navbar-options {
        width: 50%;
    }
}

@media (max-width: 950px) {
    .landing5-section1-left-title {
        font-size: 30px;
        line-height: 40px;
    }

    .landing5-section1-left-subtitle {
        font-size: 18px;
        line-height: 23px;
    }

    .landing5-section1-left {
        gap: 20px;
    }

    .landing5-section1-left-button {
        padding-top: 20px;
    }

    .landing5-section2-right-title {
        font-size: 20px;
        line-height: 25px;
    }

    .landing5-section2-body-item-content-title {
        font-size: 15px;
        line-height: 20px;
    }

    .landing5-section2-body-item-content-subtitle {
        font-size: 10px;
        line-height: 15px;
    }

    .landing5-section4-body-item-content-title {
        font-size: 20px;
        line-height: 25px;
    }

    .landing5-section4-body-item-content-subtitle {
        font-size: 15px;
        line-height: 20px;
    }

    .landing5-section4-body-item-icon {
        padding: 10px;
    }

    .landing5-section4-body-item {
        padding: 20px 20px;
    }

    .landing5-backedby-title {
        font-size: 20px;
    }

    .landing2-backedby-img {
        height: 40px;
    }

    .landing2-navbar-logo-img {
        height: 40px;
    }

    .landing2-footer-title {
        font-size: 20px;
        line-height: 25px;
    }

    .landing5navbar-options {
        width: 60%;
    }
}

@media (max-width: 850px) {
    .landing5-footer-body {
        display: none;
    }

    .landing5-footer-body-mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        gap: 20px;
    }

    .landing2-footer-mobile {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}

@media (max-width: 700px) {
    .landing5navbar-logo-image {
        height: 40px;
    }

    .landing5-section1-left-title {
        font-size: 25px;
        line-height: 35px;
    }

    .landing5-section1-left-subtitle {
        font-size: 15px;
        line-height: 20px;
    }

    .landing5-section1-left {
        gap: 20px;
    }

    .landing5-section1-left-button {
        padding-top: 20px;
    }

    .landing5-section2-right {
        padding-right: 5%;
        padding-left: 5%;

        gap: 20px;
    }

    .landing5-section2-right-title {
        font-size: 18px;
        line-height: 23px;
    }

    .landing5-section2-body {
        gap: 10px;
    }

    .landing5-section2-body-item-content-title {
        font-size: 13px;
        line-height: 18px;
    }

    .landing5-section3-left {
        padding-left: 5%;
        padding-right: 5%;

        gap: 20px;
    }

    .landing5-section4-body-item-content-title {
        font-size: 18px;
        line-height: 23px;
    }

    .landing5-section4-body-item-content-subtitle {
        font-size: 13px;
        line-height: 18px;
    }

    .landing5-section4-body-item-icon {
        padding: 10px;
    }

    .landing5-section4-body-item {
        padding: 20px 20px;
    }

    .landing5-backedby-title {
        font-size: 18px;
    }

    .landing2-backedby-img {
        height: 30px;
    }

    .landing2-navbar-logo-img {
        height: 30px;
    }

    .landing2-footer-title {
        font-size: 18px;
        line-height: 23px;
    }

    .landing5navbar-options {
        width: 70%;
    }
}

@media (max-width: 650px) {
    .landing5navbar-conatiner {
        height: 60px;
    }

    .landing5navbar-mobile-menu {
        top: 60px;
    }

    .landing5-container {
        padding-top: 60px;
    }

    .landing5navbar-logo-image {
        height: 35px;
    }

    .landing5-section4-body {
        display: flex;
        flex-direction: column;

        gap: 20px;
    }

    .landing5-section1-background {
        top: 10%;
        width: 200%;
    }

    .landing5-section4-background {
        left: -50%;
        width: 400%;
    }

    .landing5-section4-body-item {
        display: flex;
        flex-direction: row;
        justify-content: left;

        gap: 20px;
        padding: 10px;
    }

    .landing5-section4-body-item-icon {
        padding: 10px;
    }

    .landing5-section2-body-item-content {
        text-align: left;
    }

    .landing5-section2 {
        display: flex;
        flex-direction: column;

        gap: 20px;
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .landing5-section2-left {
        width: 80%;
    }

    .landing5-section2-right {
        width: 100%;
    }

    .landing5-section1 {
        display: flex;
        flex-direction: column;

        padding-bottom: 10%;
    }

    .landing5-section1-left {
        width: 100%;
    }

    .landing5-section1-right {
        width: 100%;
        margin-left: 0%;

        align-content: center;
        justify-content: center;
    }

    .landing5-section3-left {
        width: 100%;
    }

    .landing5-section3-right {
        width: 80%;
    }

    .landing5-section4 {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .landing2-footer-mobile {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .landing2-backedby-img-container {
        padding: 10px 20px;
    }

    .landing2-backedby-logos {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 400px) {
    .landing5-footer-body-mobile {
        display: flex;
        flex-direction: column;
        gap: 20px;

        padding-top: 20px;
    }
}