.contractorbidsection-subsection-left-container{
    padding: 2%;
}

.contractorbidtrades-section-price{
    border-radius: 5px;
    margin-left: 5px;
    outline: none;

    border: 1px solid lightgray;

    width: 100px;

    background-color: #F6F6FA;
}

.contractorbidtrades-section-price:hover{
    background-color: aliceblue;
}

.contractorbidtrades-section-price:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}