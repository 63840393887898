.contractorprojectsettings-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 20%;
}

.contractorprojectsettings-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contractorprojectsettings-title{
    font-weight: bold;
    font-size: 36px;
}

.contractorprojectsettings-body{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contractorprojectsettings-setting{
    border: 1px solid lightgray;
    border-radius: 5px;

    padding: 2%;

    background-color: white;
}

.contractorprojectsettings-setting-title{
    font-weight: bold;
    font-size: 20px;
}

.contractorprojectsettings-setting-desc{
    margin-bottom: 2%;
}


.contractorprojectsettings-setting-linebreak {
    margin: 15px;
    margin-top: 40px;
    margin-bottom: 40px;
}