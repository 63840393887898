.projectmap-containers{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 94vh;
}

.projectmap-map{
    margin-top: 6vh;
    width: 60%;
    height: 100%;
}

.projectmap-list{
    margin-top: 6vh;
    width: 40%;
    height: inherit;
}

.projectmap-selectedproject-container{
    margin-top: 6vh;
    padding: 5%;       
    max-height: 94vh;
    overflow-y: auto;
}

.projectmap-selectedzip-container{
    padding: 5%;
    max-height: 94vh;
    overflow-y: auto;
}

.projectmap-selectedzip-stats{
    gap: 10px;
}

.projectmap-selectedzip-project{
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    transition-duration: 0.3s;
}

.projectmap-selectedzip-project:hover{
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
    cursor: pointer;
}

.projectmap-zipcodelist-container{
    display: grid;
    grid-template-columns: 1fr 1fr;    
    gap: 10px;
}

.projectmap-zipcodelist{
    padding: 5%;
    max-height: 94vh;
    overflow-y: auto;
}

.projectmap-zipcodelist-title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.projectmap-projectlist-zipcode{
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    transition-duration: 0.3s;
    background-color: white;
}

.projectmap-projectlist-zipcode:hover {
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
    cursor: pointer;
}

.projectmap-projectlist-zipcode:hover .projectmap-projectlist-zipcode-stats-container > div:nth-child(even){
    background-color: #98c1d9;
}

.projectmap-selectedzip-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.projectmap-selectedzip-title{
    font-size: 20px;
    font-weight: bold;
}

.projectmap-selectedproject-title{
    font-weight: bold;
    font-size: 20px;
}

.projectmap-projectlist-zipcode-title{
    font-weight: bold;
}

.projectmap-projectlist-zipcode-stats{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.projectmap-projectlist-zipcode-stats-title{
    font-weight: bold;
    margin-top: 10px;
}

.projectmap-projectlist-zipcode-stats-container > div:nth-child(even){
    background-color: gainsboro;
}

.projectmap-zipcodelist-areastats-container{
    margin-top: 5%;
    border-radius: 5px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    background-color: white;
}

.projectmap-zipcodelist-areastats-title{
    font-weight: bold;
    font-size: 20px;
}

.projectmap-zipcodelist-areastats-stats-title{
    font-weight: bold;
    margin-top: 10px;
}

.projectmap-zipcodelist-areastats-stats{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.projectmap-selectedzip-stats-bids{
    font-weight: bold;
}

.projectmap-selectedzip-stat{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.projectmap-selectedzip-stats{
    padding-left: 10%;
    padding-right: 10%;
}

.projectmap-selectedzip-stats-container > div:nth-child(even){
    background-color: gainsboro;
}

.projectmap-zipcodelist-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

.projectmap-list-container{
    padding: 5%;
    max-height: 94vh;
    overflow-y: auto;
}

.projectmap-list-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

.projectmap-list-title{
    font-weight: 600;
    font-size: 20px;
}

.projectmap-list-project{
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;
    transition-duration: 0.3s;
    background-color: white;

    padding-bottom: 2%;
}

.projectmap-list-projects{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.projectmap-list-project:hover{
    box-shadow: rgb(0 0 0 / 60%) 0px 4px 4px 0px;
    cursor: pointer;
}

.projectmap-projectdetail-container{
    padding: 5%;
    max-height: 94vh;
    overflow-y: auto;
}

.projectmap-projectdetail-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.projectmap-projectdetail-title{
    font-weight: 600;
}

.projectmap-projectdetail-status-blue{
    color: #006AFF;
}

.projectmap-projectdetail-status-green{
    color: #74c005
}

.projectmap-projectdetail-desc{
    margin: 10px;
    border-left: 2px solid #2565AE;
    padding: 5px;
}

.projectmap-list-project-desc{
    padding: 5px;
    height: 80px;
    overflow: hidden;
}

.projectmap-list-stats-container{
    margin-bottom: 2%;
}

.projectmap-marker div{
    color: red;
}

.projectmap-zipcodelist-areastats-choices-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
    justify-content: center;
}

.projectmap-zipcodelist-areastats-stats-container{
    display: flex;
    flex-direction: column;
}

.projectmap-zipcodelist-areastats{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.projectmap-zipcodelist-areastats-average{
    font-size: 25px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.contractorproject-sow-section-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pdm-sow-section-title{
    font-size: 20px;
    color: #006AFF;
}

.pdm-sow-subsection-header{
    padding-top: 5px;
    padding-bottom: 5px;
}

.pdm-sow-subsection-header:hover{
    cursor: pointer;
}

.pdm-sow-subsection{
    padding-left: 10px;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
    padding-bottom: 1%;
}

.pdm-sow-subsection:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.pdm-sow-subsections{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 2%;
}

.pdm-sow-items-container{
    padding-bottom: 2%;
}

.projectmap-sow-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1%;
}

.projectmap-collapseall-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.pdm-sow-subsection-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.projectmap-projectdetail-status-yellow{
    color: #FFA500; 
}

.projectmap-list-desc-link{
    color: #006AFF;
    text-decoration: none;
}

.projectmap-list-desc-link:hover{
    text-decoration: underline;
}