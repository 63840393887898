.contractorfinalize-details-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2%;

    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 2%;
}

.contractorfinalize-addsubsection-button{
    margin-left: 2%;
}

.viewfinalizesow-section-body-container{
    padding: 2%;
    position: relative;
}

.viewfinalizedsow-section-comment-container{
    position: absolute;
    /*hover to the right*/
    left: -28%;
}

.viewfinalizedsow-section-comment{
    outline: none;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 2%;

    background-color: #F6F6FA;
}

.viewfinalizedsow-section-comment:hover{
    background-color: aliceblue;
}

.viewfinalizedsow-section-comment:focus{
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.contractorbid-section-comment-container{
    position: relative;
}

.contractorfinalize-section-header-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}