.landing-logo-img {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 60px;
  right: 50px;
}

.homepage-mobile-image-container{
  display: none;
}

.button-29:disabled {
  opacity: 0.3;
}

/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #95f9fd 0, #1dbaff 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-flex;
  height: 60px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 25px;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

.landing-background {
  background-image: url("https://bobyard-public-images.s3.us-west-2.amazonaws.com/backgroundtotal.png");
  background-size: cover;
}

.landing-header {
  text-align: center;
  padding-top: 12%;
  padding-left: 20%;
  padding-right: 20%;
  height: 100vh;

  padding-bottom: 5%;
  margin-bottom: 5%;
}

.landingpagecontractor-header{
  text-align: center;
  padding-top: 15%;
  padding-left: 20%;
  padding-right: 20%;
  height: 100vh;
}

.landingpagecontractor-takeoff-header{
  padding-top: 10%;
}

.contractorlanding-stanford{
  color: #8C1515;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
}

.landing-title {
  font-size: 65px;
  font-weight: 800;
  margin-bottom: 20px;
  line-height: 70px;
  text-align: center;
  display: none;
}

.landing-title-1230{
  font-size: 65px;
  font-weight: 800;
  margin-bottom: 20px;
  line-height: 70px;
  text-align: center;
}


.landing-header-bottom{
  margin-top: 2%;
}

.landing-register-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  gap: 5%;
}

.landing-desc-try{
  font-size: 20px;
  margin-top: 5%;
  margin-bottom: 2%;
}

.homepage-process-container{
  margin-bottom: 15%;
}

.landing-popup{
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 300px;
  height: 400px;
  background-image: url("https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-06-17+at+10.22.04+AM.png");
  background-size: cover;

  z-index: 1000;

  border-radius: 5px;
  box-shadow: rgb(0,0,0, 50%) 0px 0px 15px 0px;
  padding: 10px;
}

.landing-popup-title{
  margin-top: 15%;

  font-size: 30px;
  line-height: 30px;
  font-weight: bold;

  text-align: center;
}

.landing-popup-body{
  margin: 5%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.landing-popup-footer{
  text-align: center;
  font-size: 20px;
}

.landing-free-trial{
  border: 5px;
  background-color: #FFE492;

  text-align: center;

  width: 200px;

  margin-left: auto;
  margin-right: auto;

}

@media screen and (max-width: 1230px) {
  .landing-title-1230 {
    display: none;
  }

  .landing-title {
    display: block;
  }
}

.landing-title-name {
  /*color: #05adf7;*/
  color: #006AFF;
}

.landing-desc {
  font-size: 25px;
  color: black;
  margin-bottom: 20px;
  padding-left: 15%;
  padding-right: 15%;
}

.landing-error {
  color: red;
  font-size: small;
}

.landing-success {
  color: black;
  font-size: 20px;
  margin-top: 10px;
}

.landing-form__group {
  position: relative;
  padding: 15px 0 0;
  width: 50%;
  display: inline-block;
}

.landing-form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.landing-selectdate-question {
  text-align: left;
}

.selectdate-container {
  display: block;
}

.selectdate {
  position: relative;
  width: 60%;
  float: left;
}

.landing-form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  color: black;

  cursor: text;
}

.landing-form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: #006AFF;
  border-image-slice: 1;
}

.landing-form__field:focus~.landing-form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  color: black;
}

/* reset input */
.landing-form__field:required,
.landing-form__field:invalid {
  box-shadow: none;
}

input:-webkit-autofill::first-line {
  font-size: 1.3rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.down-arrow {
  position: absolute;
  top: calc(100vh - 60px);
  left: calc(50%);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: 20px;
  }

  100% {
    margin-top: 0;
  }
}

.down-arrow {
  animation: jumpInfinite 1.5s infinite;
}

.scroll-down-arrow {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
}

.aboutus-container {}

.homepage-header {
  height: 70vh;
  padding-top: 10%;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 5%;
  /*background-color: lightblue;*/

  display: grid;
  grid-template-columns: 1fr 1fr;
}

.homepage-header-startnow-container {
  margin-top: 20%;
  text-align: center;
}

.homepage-header-options {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.homepage-header-title {
  font-size: 60px;
  font-weight: 400;
}

.homepage-header-desc {
  font-size: 30px;
}

.homepage-header-link {
  font-size: 20px;
  text-decoration: none;
  color: blue;
}

.homepage-header-link:hover {
  text-decoration: underline;
}

.homepage-process {
  margin-top: 5%;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}

.homepage-step-desc-container {
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 80%;
}

.homepage-steps-container {
  padding-top: 15%;
  display: flex;
  flex-direction: column;
  gap: 30%;
}

.homepage-step {
  margin-bottom: 25%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.homepage-step-title {
  font-weight: bold;
  font-size: 45px;
  width: 75%;
  margin-bottom: 5px;
}

.homepage-step-desc {
  width: 80%;
  margin-bottom: 20px;
  font-size: 20px;
}

.homepage-final {
  padding-top: 5%;
  padding-bottom: 40vh;
  text-align: center;
}

.homepage-final-title {
  font-weight: bold;
  font-size: 45px;

  margin-bottom: 20px;
}

.homepage-startnow-link {
  color: black;
  text-decoration: none;
  font-size: 30px;
  scroll-behavior: smooth;
}

.fancy-button {
  font-size: 20px;
  align-items: center;
  appearance: none;
  background-color: #149BF3;
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 10px 50px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.fancy-button:active,
.fancy-button:focus {
  outline: none;
}

.fancy-button:hover {
  background-color: #4F58FD;
}

.fancy-button:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

.homepage-process-desc {
  font-size: 25px;
  width: 40%;
}

.homepage-contract-img {
  height: 300px;
  border: 2px solid gray;
  border-radius: 5px;
  box-shadow: 5px 5px 5px gray;
}

.homepage-map-img {
  height: 300px;
  border: 2px solid gray;
  border-radius: 5px;
  box-shadow: 5px 5px 5px gray;
}

.homepage-sow-image {
  height: 300px;
  border: 2px solid gray;
  border-radius: 5px;
  box-shadow: 5px 5px 5px gray;
}

.homepage-commpare-img {
  height: 300px;
  border: 2px solid gray;
  border-radius: 5px;
  box-shadow: 5px 5px 5px gray;
}

.homepage-step-number {
  align-items: center;
  justify-content: center;
  display: flex;
}

.homepage-step-desc-container-left {
  margin-left: 20%;
}

.homepage-step-desc-container-right {
  margin-left: 10%;
}

.homepage-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  gap: 5px;
  padding-right: 5px;
  font-weight: bold;
}

.homepage-footer-logoimg {
  width: 75px;
  height: 75px;
}

.homepage-footer-name {
  font-size: 50px;
  font-weight: bold;
  color: #1DBAFE;
}


.landingpagecontractor-takeof-video{
  width: 600px;
  height: 338px;
}

@media only screen and (max-width: 500px) {
  .landingpagecontractor-header{
    text-align: center;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;
    height: 100vh;
  
    padding-bottom: 5%;
    margin-bottom: 5%;
  }

  .landingpagecontractor-takeof-video{
    width: 250px;
    height: 141px;
    margin: 0px;
    padding: 0px;
  }

  .landing-register-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
  }
    
  .homepage-step{
    display: flex;
    flex-direction: column;
  }

  .homepage-footer-container {
    margin-right: auto;
    margin-left: 1%;
  }

  .homepage-footer-name {
    display: none;
  }

  .homepage-step-title {
    font-size: 36px;
  }

  .down-arrow {
    position: absolute;
    top: calc(100vh - 60px);
    left: calc(50%);
  }

  .homepage-steps-container {
    padding: 0px;
    margin: 0px;
  }

  .homepage-step-desc-container {
    margin: 0px;
    width: 80vw;
    padding: 0px;
  }

  .homepage-step-desc-container-right {
    margin: 0px;
  }

  .homepage-step-desc-container-left {
    margin: 0px;
  }

  .landing-background {
    overflow: hidden;
  }

  .landing-header {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20%;
    margin-bottom: 20%;
    padding-bottom: 5%;
  }

  .landing-wrapper {
    padding: 0;
    padding-top: 50%;
  }

  .landing-title {
    font-size: 25px;
    line-height: 30px;
    padding: 0;
    text-align: center;
    margin-top: 15%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .landing-desc {
    margin-top: 30px;
    font-size: 15px;
    width: 80%;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .landing-logo-img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50px;
    right: 10px;
  }

  .landing-form__group {
    width: 75%
  }

  .landing-form__field:placeholder-shown~.landing-form__label {
    font-size: 1.0rem;
  }

  .landing-form__field {
    font-size: 1.0rem;
  }

  .button-29 {
    margin-top: 10px;
  }

  .landing-error {
    font-size: 15px;
  }

  .landing-success {
    font-size: 15px;
  }

  .homepage-step-number{
    display: none;
  }

  .homepage-mobile-image-container{
    display: block;
    margin-bottom: 5%;
  }

  .homepage-step-desc{
    width: 100%;
  }
  
  .homepage-mobile-image{
    height: auto;
    width: 80%;

    margin-left: 10%;
    margin-left: 10%;

    border: 2px solid gray;
    border-radius: 5px;
    box-shadow: 5px 5px 5px gray;
  }

  .contractorsinfo-header-title{
    font-size: 25px;
  }

  .button-29{
    font-size: 20px;
    height: 50px;
    width: fit-content;
  }
}

.landing-form-conatiner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.landing-alphacontainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 1%;
}

input[type="checkbox"] {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 25px;
  height: 25px;
  border: 2px solid #007bff;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}

input[type="checkbox"]::before {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: white;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"]:checked::before {
  opacity: 1;
}

input[type="checkbox"]+label {
  margin-left: 8px;
  font-weight: normal;
  color: #007bff;
  cursor: pointer;
}

input[type="checkbox"]+label:hover {
  text-decoration: underline;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.contractorsinfo-testimonial-container{
  margin-top: 10%;
  padding-top: 10%;
  margin-bottom: 10%;
}