.contractoraccount-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.contractoraccount-plans-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    width: 70%;
    text-align: center;

    margin-left: 15%;
}