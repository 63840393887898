.demo2-container {
    z-index: 1;

    position: relative;
    /*Lexend Deca*/
    font-family: 'Lexend Deca', sans-serif !important;
    overflow: hidden;
    height: 100vh;
    width: 100vw;

    overflow: hidden;

    background-color: #fbfcfd;
}

.demo2-content {
    height: 100vh;
    width: 100vw;

    padding-top: 80px;

    overflow: hidden;
}

.demo2-navbar {
    position: absolute;
}

.demo2-iframe {
    z-index: 1;

    width: 100%;
    height: 80vh;

    overflow: hidden;
}

@media (max-width: 1000px) {
    .demo2-iframe {
        display: block;
        z-index: -1;
        height: 92vh;
    }
}