.templatelist-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 10%;
    height: fit-content;
}

.templatelist-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.templatelist-title{
    font-size: 30px;
    font-weight: bold;
}

.templatelist-desc{
    margin-bottom: 1%;
}

.templatelist-instructions{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 2%;
}

.templatelist-instructions-text{
    width: 50%;
    line-height: 20px;
}

.templatelist-templates{
    margin-top: 2%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2%;
}

.templatelist-preview-container{
    background-color: white;
    border-radius: 5px;
    border: 1px solid lightgray;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
    padding: 2%;
}

.templatelist-preview-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.templatelist-preview-sections-container{
    padding: 2%;
    padding-left: 5%;
}