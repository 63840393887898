.contractorbidtrades-section-title{
    font-weight: bold;
}

.contractorbidtrades-section-header{
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 2%;
    padding-bottom: 0px;
}

.contractorbidtrades-section-total{
    font-weight: bold;
}

.contractorbidtrade-trade-total{
    color: #006AFF;
    font-weight: bold;
}

.contractorbidtrade-trade-container{
    position: relative;

    border-radius: 5px;
    background-color: white;
    margin-top: 1%;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
}

.contractorbidtrade-extra-section-container{
    border-radius: 5px;
    background-color: white;
    margin-top: 2%;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    
    padding: 2%;
}

.contractorbidtrades-section-desc{
    padding: 2%;
    padding-top: 0px;
    padding-bottom: 0px;
}

.contractorbidtrade-trade-total-number{
    display: inline-block;
    width: 100px;

    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: whitesmoke;

    border-radius: 5px;

    color: #006AFF;
    font-weight: bold;
}

.contractorbidtrade-trade-buttons-container{
    display: flex;
    align-items: center;
    gap: 10px;
}

.contractorbidtrade-trade-buttons{
    text-align: center;

    width: 150px;
}

.contractorbid-warrantyitem-container{
    display: grid;
    grid-template-columns: 10fr 5fr 1fr;
    align-items: center;
    gap: 10px;
}

.contractorbidtrade-payment-total{
    text-align: right;
    padding-right: 25%;
    margin-top: 1%;
}

.contractorbid-subsection-cost-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 1%;
    padding: 1%;
    border-radius: 5px;
    gap: 5px;

    border: 1px solid lightgray;
}

.contractorbid-subsection-cost{
    padding: 2%;
    border-radius: 5px;

    border: 1px solid lightgray;
}

.contractorbidtrade-trade-cost-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1%;
    padding: 1%;
    border-radius: 5px;
    gap: 5px;

    border: 1px solid lightgray;
}

.contractorbid-subsection-cost-input{
    width: 100px;
    border: 1px solid lightgray;
    outline: none;
    border-radius: 5px;

    background-color: #f6f6fA;
}

.contractorbid-subsection-cost-input:hover{
    background-color: aliceblue;
}

.contractorbid-subsection-cost-input:focus{
    background-color: aliceblue;
    border-color: #006AFF;
}

.contractorbid-subsection-cost-title{
    text-align: center;
}

.contractorprojectbid-owner-section-header{
    position: relative;

    display: grid;
    align-items: center;
    grid-template-columns: 1fr 14fr 1fr;
    gap: 10px;

    padding: 2%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorprojectbid-section-header{
    position: relative;

    display: grid;
    align-items: center;
    grid-template-columns: 1fr 14fr 4fr 2fr;
    gap: 10px;

    padding: 2%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorprojectbid-section-header:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
}

.contractorprojectbid-section-title{
    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;

    font-size: 20px;
    font-weight: bold;
    color: #006AFF;
}

.contractorprojectbid-owner-subsection-header{
    position: relative;

    display: grid;
    grid-template-columns: 1fr 15fr 6fr 3fr;
    align-items: center;

    padding: 2%;
    padding-top: 1.25%;
    padding-bottom: 1.25%;

    background-color: #EEF4FF;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorprojectbid-subsection-header{
    position: relative;

    display: grid;
    grid-template-columns: 1fr 15fr 6fr 5fr;
    align-items: center;

    padding: 2%;
    padding-top: 1.25%;
    padding-bottom: 1.25%;

    background-color: #EEF4FF;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorprojectbid-subsection-header:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
}

.contractorprojectbid-subsection-comment-container{
    position: absolute;
    left: 105%;
    top: 0%;
    width: 28%;

    background-color: white;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
    padding: 5px;

    padding-bottom: 0px;

    z-index: 998;
}

.contractorprojectbid-subsection-comment-container:hover{
    cursor: default;
}
