.contractorpublicprofile-container{
    padding: 5% 20% 5% 20%;
}

.contractorpublicprofile-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contractorpublicprofile-title{
    font-weight: bold;
    font-size: 36px;
}

.contractorpublicprofile-profile-title{
    font-weight: bold;
    font-size: 20px;
}

.contractorpublicprofile-profile-link{
    text-decoration: none;
    color: black;
}

.contractorpublicprofile-profile-link:hover{
    color: #006AFF;
    cursor: pointer;
}

.contractorpublicprofile-detail{
    width: 100%;
    border-width: 1px;
    padding-left: 10px;
    border-style: hidden;
    border: 1px solid lightgray;
    background-color: #F6F6FA;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.contractorpublicprofile-details{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 4%;
}

.contractorpublicprofile-desc-container{
    padding: 1%;
    margin-left: 1%;
    border-left: 2px solid #006AFF;
    white-space: pre-wrap;
    margin-bottom: 2%;
}

.contractorpublicprofile-details-container{
    margin-top: 3%;
}

.contractorpublicprofile-detail-header{
    width: 150px;
    text-align: center;
    vertical-align: middle;
    padding-top: 2px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #006AFF;
    color: white;
}

.contractorpublicprofile-details-request-container{
    display: grid;
    grid-template-columns: 3fr 2fr;
}

.contractorpublicprofile-request-select{
    width: 100%;
    padding: 1%;
    border-radius: 5px;
    margin-top: 2%;
}

.contractorpublicprofile-project-request-details{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: hsla(0, 0%, 0%, 0.15) 0px 2px 4px 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 4%;
}

.contractorpublicprofile-project-container{
    margin-top: 2%;
    line-height: 18px;
    margin-bottom: 2%;
}

.contractorpublicprofile-project-link{
    text-decoration: none;
    color: black;
}

.contractorpublicprofile-project-link:hover{
    color: #006AFF;
    cursor: pointer;
}

.contractorpublicprofile-project-request-message{
    color: #006AFF;
}

.projectdetail-substep-table{
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.projectdetail-status-addedcontractors-detail{
    display: grid;
    grid-template-columns: 2fr 1fr;
}