.contractorlist-container{
    padding: 5% 20% 5% 20%;
}

.contractorlist-contractors-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 20px;
    margin-top: 2%;
}

.contractorlist-contractor-container{
    padding: 10px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorlist-contractor-container:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.contractorlist-contractor-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.contractorlist-contractors-link{
    text-decoration: none;
    color: black;
}

.contractorlist-contractors-link:hover{
    color: black;
}

.contractorlist-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.contractorlist-title{
    font-weight: bold;
    font-size: 36px;
}

.contractorlist-ownprofile:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractorlist-contractor-nolicense{
    color: lightcoral;
}

.contractorlist-contractors-website-link{
    text-decoration: none;
    color: #006AFF;
}

.contractorlist-contractors-website-link:hover{
    text-decoration: underline;
}