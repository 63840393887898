.demo-container{
    overflow: hidden;

    padding-top: 7%;
}

.demo-iframe{
    width: 100%;
    height: 100vh;
}

.demo-navbar{
    position: fixed;

    width: 100%;

    top: 0;
    left: 0;

    padding-top: 2%;
    padding-bottom: 2%;
    background-color: #043873;
    color: white;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    padding-left: 10%;
    padding-right: 10%;
}

.demo-navbar-logo{
    top: 5%;
    left: 2%;
}

@media only screen and (max-width: 500px) {
    .demo-navbar{
        padding: 10%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .demo-container{
        padding-top: 31%;
        overflow: hidden;
    }

    .demo-iframe{
        padding-bottom: 30%;
    }
}