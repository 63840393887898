.career-container{
    background-color: #043873;
}

.career-heading{
    padding-top: 5%;
    padding-bottom: 1%;
    padding-left: 15%;
    padding-right: 15%;

    color: whitesmoke;
    font-size: 19px;
}

.career-heading-title{
    font-size: 40px;
    margin-bottom: 1%;
}

.career-body{
    display: grid;
    grid-template-columns: 1fr 3fr;
    background-color: #043873;
    color: whitesmoke;

    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 15%;

    font-size: 19px;
}

.career-left{
    padding-left: 5%;
    padding-right: 30%;
}

.career-left-heading{
    font-size: 25px;
    font-weight: 600;
}

.career-left-role{
    padding-left: 5%;
}

.career-left-role:hover{
    color: #FFE492;
    cursor: pointer;
}

.career-left-role-active{
    color: #FFE492;
    background-color: #006afe;
}

.career-role-title{
    font-size: 20px;
    font-weight: 600;
}

.career-role-section-title{
    font-style: italic;
    color: #177fff;
}

.career-role-apply{
    color: white;

    background-color: #4F9CF9;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    width: 100px;

    border-radius: 8px;
    outline: none;
    border: none;

    text-decoration: none;
}

.career-role-apply:hover {
    cursor: pointer;
    background-color: #006AFF;

    transition: background-color 0.5s;
}

.career-role-apply-link{
    color: white;
    text-decoration: none;
}

.career-role-apply-link:hover{
    color: white;
    text-decoration: none;
}

.career-role-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 10%;
}

@media only screen and (max-width: 500px) {
    .career-heading{
        font-size: 16px;

        padding-left: 10%;
        padding-right: 10%;
    }   

    .career-body{
        display: block;
        padding: 0%;
    }

    .career-left{
        padding-left: 10%;
        padding-right: 10%;
    }

    .career-right{
        margin-top: 10%;

        padding-left: 10%;
        padding-right: 10%;
    }

    .career-role-body{
        font-size: 16px;
        padding-bottom: 25%;
    }
}