.editprojectdetail-container{
    height: fit-content;
    padding-top: 5%;
    padding-bottom: 10%;
    padding-left: 20%;
    padding-right: 20%;
}

.editprojectdetail-title{
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 20px;
}

.editprojectdetail-property{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editprojectdetail-property-address {
    display: flex;
    flex-direction: row;

    border-radius: 5px;

    height: 30px;
}

.editprojectdetail-property-details{
    width: 120px;
    text-align: center;
    vertical-align: middle;
    padding-top: 2px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #006AFF;
    color: white;
}

.editprojectdetail-property-address-input-edit{
    width: 100%;
    border-width: 1px;
    padding-left: 10px;
    border-style: hidden;
    border: 1px solid lightgray;
    background-color: #F6F6FA;
}

.editprojectdetail-property-address-input-edit:hover {
    background-color: #EEF4FF;
}

.editprojectdetail-property-address-input-edit:focus {
    border-radius: 0px;
    border-color: #006AFF;
    background-color: #EEF4FF;
    outline: none;
}

.editprojectdetail-property-address-input {
    width: 100%;
    border-width: 1px;
    padding-left: 10px;
    border-style: hidden;
    border: 1px solid lightgray;
    background-color: #F6F6FA;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.editprojectdetail-property-address-input:hover {
    background-color: #EEF4FF;
}

.editprojectdetail-property-address-input:focus {
    border-color: #006AFF;
    background-color: #EEF4FF;
    outline: none;
}

.editprojectdetail-property-details-desc{
    width: 100%;
    flex: 1;
    padding: 10px;
    
    border: none;
    border-left: 2px solid whitesmoke;
    resize: none;
    resize: vertical;

    background-color: #F6F6FA;
    box-shadow: inset 0 0 3px gray;
}

.editprojectdetail-property-details-desc:hover{
    background-color: #EEF4FF;
}

.editprojectdetail-property-details-desc:focus{
    background-color: #EEF4FF;
    border: none;
    outline: none;
    border-left: 2px solid #006AFF;
}

.editprojectdetail-property-savebutton{
    width: 10%;
    outline: none;
    border: none;
    color: white;
    background-color: #006AFF;
    border: 1px solid #006AFF;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;
}

.editprojectdetail-property-savebutton:hover{
    background-color: rgb(13, 69, 153);
    border-color: rgb(13, 69, 153);
    cursor: pointer;
}

.editprojectdetail-addsection-button {
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    background-color: #CDD9ED;
}
  
.editprojectdetail-addsection-button:hover {
    background-color: #98c1d9;
    cursor: pointer;
}
  
.editprojectdetail-addsection-button:focus {
    background-color: #98c1d9;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
}

.editprojectdetail-sections{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 1%;
}

.editprojectdetail-property-details-desc-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2%;
    height: 24px;
}

.editprojectdetail-footer{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.editprojectdetail-footer-nextbutton{
    height: fit-content;
}

.editprojectdetail-autocomplete-dropdown-container{
    padding-left: 10px;
}

.editprojectdetail-suggestion-item-active:hover{
    font-weight: bold;
    cursor: pointer;
}

.editprojectdetail-header-button{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.sidenav-container{
    position: fixed;
    z-index: 800;

    top: 20vh;
    right: 0px;
    padding-right: 10px;

  
    max-height: calc(70vh);
    overflow: hidden;

    font-size: small;

    padding-top: 3%;
    padding-bottom: 3%;

    text-align: right;
}

.sidenav-container:before{
    content: ''; 
    display: block;
    position: fixed;
    width: 100%;
    bottom: 10%;
    height: 10%;
    pointer-events: none;
}

.sidenav-container:after{
    content: ''; 
    display: block;
    position: fixed;
    width: 100%;
    top: 20%;
    height: 5%;
    pointer-events: none;
}

.sidenav-container ul{
    list-style: none;

    max-width: 15vw;
}

.nav-item{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nav-item:hover{
    color: #006AFF;
    cursor: pointer;
}

.nav-item-active{
    color: #006AFF;
}

.nav-item-active:hover{
    color: #006AFF;
    cursor: pointer;
}

.epd-desc{
    width: 100%;
    padding: 10px;
    
    border: none;
    border-left: 2px solid whitesmoke;

    background-color: #F6F6FA;
    box-shadow: inset 0 0 3px gray;

    border-radius: 5px;
}

.epd-desc:hover{
    background-color: #EEF4FF;
}

.epd-desc:focus{
    background-color: #EEF4FF;
    border: none;
    outline: none;
    border-left: 2px solid #006AFF;
}

.editprojectdetail-viewtemplates-button{
    margin-right: 10px;
}

.editprojectdetail-options{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3%;
    margin-bottom: 2%;
    height: 70px;
}

.editprojectdetail-options-template{    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: white;
    border-radius: 5px;
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
    gap: 10px;

    box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px 0px;

    height: inherit;
}