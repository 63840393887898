.comparebids-trade-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    padding-top: 2%;
    padding-bottom: 2%;
}

.comparebids-subsection-header{
    padding: 0.5% 2% 0.5% 2%;

    background-color: #EEF4FF;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comparebids-subsection-header:hover{
    cursor: pointer;

    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}   

.projectbids-payment-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}