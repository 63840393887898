.bidedit-edit-subsection-modal-items-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.bidedit-edit-subsection-modal{
    width: 70vw;
    max-width: 70vw;
    min-width: 70vw;

    padding-bottom: 10%;
}

.bidedit-extra-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contractorbid-options-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 2%;
}

.finalizebid-section-total{
    text-align: right;
    padding-right: 5%;
    font-weight: bold;
    color: #006AFF;
}

.finalizebid-desc{
    margin-bottom: 0px;
}

.contractorbid-title-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}