.projectviewcontract-container{
    height: 100vh;
}

.projectviewcontract-file{
    width: 100%;
    height: 100%;
}

.projectviewcontract-container{
    height: 100vh;    
    display: grid;
    grid-template-columns: 7fr 3fr;
    overflow-y: hidden;
}