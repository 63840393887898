.contractorbid-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.contractorbid-section-container{
    border-radius: 5px;
    background-color: white;
    margin-top: 2%;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 4px 0px;
}

.contractorbid-extra-section-container{
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 4px 0px;
    background-color: white;
    margin-top: 2%;
    padding: 2%;   
}


.contractorbid-subsection-container{
    margin-top: 1%;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorbid-item-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
    gap: 10px;
}

.contractorbid-item-desc{
    width: 40%;
}

.contractorbid-item-bids{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.contractorbid-item-bid{
    width: 100%;
    height: 24px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-right: 1px;
}

.contractorbid-subsection-quantity{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
}

.contractorbid-item-total{
    width: 100px;
}

.contractorbid-subsection-title{
}

.contractorbid-projecttotal-container{
    padding-top: 2%;
    padding: 2%;
    text-align: right;
    font-weight: bold;
}

.contractorbid-projecttotal{
    color: #006AFF;
    font-size: 20px;
}

.contractorbid-section-total{
    text-align: right;
    font-weight: bold;
    color: #006AFF;
}

.contractorbid-section-title{
    font-weight: bold;
    font-size: 20px;
    color: #006AFF;
}

.contractorbid-title{
    font-weight: bold;
    font-size: 36px;
}

.contractorbid-address{
}

.contractorbid-subsection-header{
    display: grid;
    grid-template-columns: 20fr 6fr 3fr;
    align-items: center;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;

    background-color: #EEF4FF;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.contractorbid-subsection-header:hover{
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.contractorbid-extra-title{
    font-weight: bold;
    font-size: 20px;
    color: #006AFF;
}

.contractorbid-subsection-extra-desc{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;

    max-height: 200px;
}

.contractorbid-extra-desc{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
    width: 100%;

    max-height: 400px;

    background-color: #F6F6FA;
}

.contractorbid-extra-desc:hover{
    background-color: aliceblue;
}

.contractorbid-extra-desc:focus{
    background-color: aliceblue;
    outline: none;
    border: 1px solid #006AFF;
}

.contractorbid-extra-cost{
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 25%;
    height: 24px;

    background-color: #F6F6FA;
}

.contractorbid-extra-cost:hover{
    background-color: aliceblue;
}

.contractorbid-extra-cost:focus{
    background-color: aliceblue;
    outline: none;
    border: 1px solid #006AFF;
}

.contractorbid-status{
    font-weight: bold;
    margin-top: 5px;
}

.contractorbid-back-button{
    margin-left: 20px;
}

.contractorbid-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.contractorbid-back-link{
    text-decoration: none;
    color: black;
}

.contractorbid-back-link:hover{
    color: black;
}

.contractorbid-subsection-body-container{
    padding: 1%;
    display: grid;
    grid-template-columns: 8fr 3fr;
    align-items: center;
    gap: 5px
}

.contractorbid-subsection-top-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    margin-bottom: 2%;
}

.contractorbid-subsection-bottom-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    margin-top: 1%;
}

.contractorbid-subsection-items-container{
    padding: 1%;
    padding-top: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
}

.contractorbid-edit-subsection-modal{
    width: 50vw;
    max-width: 50vw;
    min-width: 50vw;

    padding-bottom: 10%;
}

.contractorbid-edit-subsection-modal-items-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.contractorbid-edit-subsection-modal-body{
    padding: 2%;
}

.contractorbid-edit-subsection-modal .modal-dialog {
    max-width: 50vw;
    max-height: 50vh;
}

.contractorbid-item-detail-container{
    display: grid;
    grid-template-columns: 10fr 1fr 10fr;
    gap: 10px;
}

.contractorbid-total-save-container{
    display: flex;
    justify-content: space-around;
}

.contractorbid-item-extradesc-save-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
}

.contractorbid-subsection-save-button{
    padding-left: 10px;
    padding-right: 10px;
}

.contractorbid-subsection-additembutton-container{
    margin-top: 10px;
}

.contractorbid-section-collapseall{
    display: flex;
    gap: 10px;
}

.contractorbid-section-collapseall-expand{
    font-weight: bold;
    font-size: 20px;
}

.contractorbid-section-collapseall-collapse{
    font-weight: bold;
    font-size: 20px;
}

.contractorbid-section-collapseall-expand:hover{
    cursor: pointer;
    color: #006AFF;
}

.contractorbid-section-collapseall-collapse:hover{
    cursor: pointer;
    color: #006AFF;
}

.homeownerbid-section-body-container{
    padding: 2%;
    padding-top: 1%;
}

.contractorbid-section-header{
    padding: 2%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorbid-section-header:hover{
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.contractorbid-section-conditional-title{
    font-weight: bold;
}

.contractorbid-section-conditional-header{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contractorbid-addedconditionalsow{
    margin-top: 1%;
    margin-bottom: 1%;

    border: 1px solid lightgray;
    border-radius: 5px;

    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px 0px;
}

.contractorbid-addedconditionalsow:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 0px;
}

.contractorbid-addedconditionalsow-header{
    padding: 0.5% 2% 0.5% 2%;
}

.contractorbid-addedconditionalsow-header:hover{
    cursor: pointer;
}


.contractorbid-sow-container{
    padding-left: 2%;
    padding-right: 2%;
}

.contratorbid-conditionalsow-severity{
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 10px;
}

.contratorbid-conditionalsow-severity-bid{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contratorbid-conditionalsow-severity-header{
    display: flex;
    align-items: center;
    gap: 20%;
}

.contractorbid-editconditionalsow-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.contractorbid-subsection-edit{
    position: relative;
}

.contractorbid-subsection-buttons-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: space-between;
    gap: 10px;
    position: relative;
    right: -4%;
}

.contractorbid-extra-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homeownerbid-desc-header{
    display: flex;
    gap: 3%;
    align-items: center;
    margin-bottom: 1%;
    margin-top: 3%;
}

.projectbids-addbid-input{
    background-color: #F6F6FA;    
}

.projectbids-addbid-input:hover{
    background-color: #F6F6FA;
}

.contractorbid-subsection-quantity-container{
    display: grid;
    grid-template-columns: 10fr 11fr;
}

.contractorbid-subsection-desc-container{
    padding: 1%;
    padding-bottom: 0px;
}

.contractorbid-subsection-desc{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;

    background-color: #F6F6FA;
}

.contractorbid-subsection-desc:hover{
    background-color: aliceblue;
}

.contractorbid-subsection-desc:focus{
    background-color: aliceblue;
    outline: none;
    border: 1px solid #006AFF;
}


.homeownerbid-section-desc-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.homeownerbid-desc-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.homeowner-showsections{
    display: flex;
    gap: 10px;
}

.contractorbid-desc-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contractorbid-desc{
    margin-bottom: 0px;
}

.contractorbid-details-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.contractorbid-view-buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 32%;
    gap: 2%;

    margin-top: 2%;
}

.contractorbid-view-button{
    width: 100%;
}


.contractorbid-subsection-total{
    width: 100px;
}