.infohover-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.infohover2-button{
    position: relative;
    display: inline-block;
}   

.infohover2-icon{
    color:white;
    background-color: #006AFF;
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 100%;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
}

.infohover-icon {
    color:white;
    background-color: #006AFF;
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 100%;
    font-size: 12px;
    text-align: center;
    text-decoration: none;

    position: fixed;
    bottom: 5px;
    right: 5px;
}

.infohover2-children{
    position: absolute;
    right: 5px;
    width: 200px;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #006AFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    z-index: 9999;

    font-weight: normal;
    text-decoration: none;
}

.infohover3-message{
    position: absolute;
    width: 200px !important;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #006AFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    z-index: 9999999;

    font-weight: normal;
    text-decoration: none;

    white-space: pre-wrap;

    text-align: left;

    animation: show 500ms linear 0s 1
}

.infohover3-message-left{
    left: -190px;
    bottom: 25px;
}

.infohover3-message-left-bottom{
    left: -150px;
    top: 25px;
}

@keyframes show {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
  
.infohover-message {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 200px;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #006AFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    z-index: 9999;
}

