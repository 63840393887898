.editprojectdetail-conditionalsow-addbutton{
    margin-top: 10px;
    margin-left: 10px;
}

.editconditionalsowmodal-header{
    margin-bottom: 2%;
}

.editconditionalsowmodal-title{
    font-size: 20px;
    font-weight: bold;
}